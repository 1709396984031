/* ======================================== */
/* media grid */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$media-grid-gutter: 4rem;

.media-grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: $media-grid-gutter;
    align-items: flex-start;
    margin: 4rem 0;

    &--stacked {
        .media-grid__item {
            flex-basis: 100%;

            @include breakpoint(sm) {
                display: flex;
                align-items: flex-start;
                gap: $media-grid-gutter;
            }
        }
    }

    &--columns-2 {
        .media-grid__item {
            @include breakpoint(sm) {
                flex-basis: calc(50% - (#{$media-grid-gutter}));
                max-width: calc(50% - (#{$media-grid-gutter}/2));
            }
        }
    }

    &--columns-3 {
        .media-grid__item {
            @include breakpoint(sm) {
                flex-basis: calc(50% - (#{$media-grid-gutter}));
                max-width: calc(50% - (#{$media-grid-gutter}/2));
            }
            @include breakpoint(lg) {
                flex-basis: calc(33.3% - (#{$media-grid-gutter}));
                max-width: calc(33.3% - (#{$media-grid-gutter}/2));
            }
        }
    }

    &__item {
        flex-basis: 100%;
        flex-grow: 1;

        > *:last-child {
            margin-bottom: 0;
        }

        &-media {
            .media-grid--stacked & {
                @include breakpoint(sm) {
                    flex-grow: 1;
                    flex-basis: calc(33.333333% - #{$media-grid-gutter*0.675});
                    flex-shrink: 0;
                    display: flex;
                    justify-content: flex-start;
                }
            }
        }
        &-content {
            .media-grid--stacked & {
                @include breakpoint(sm) {
                    flex-grow: 1;
                }
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
        
        &-media {
            position: relative;
            width: 100%;
            margin-bottom: 2rem;
            display: flex;
            align-items: flex-end;

            .media-grid--stacked & {
                @include breakpoint(sm) {
                    justify-content: center;
                    margin-bottom: 0;

                    &--logo {
                        .media-grid__item-picture {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }

            &--image {
                .media-grid__item-img {
                    width: 100%;
                }
            }

            &--logo {
                .media-grid__item-img {
                    max-width: 100%;
                    max-height: 100px; // Matched to api image height - need to consider svgs that don't get resized
                }
            }

            &--icon {
                width: 100px;

                > svg {
                    width: 6rem;
                }

                .theme-icon-bg {
                    fill: rgb(var(--t-icon-bg));
                }

                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-icon-content));
                }

                .theme-icon-content-fill {
                    fill: rgb(var(--t-icon-content));
                }
            }
        }
        
        &-picture {
            position: relative;
            width: 100%;
        }
        
        &-img {
            display: block;
        }
    }
}
