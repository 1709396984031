/* ======================================== */
/* block image text */
/* ======================================== */

@use "abstracts" as *;

.image-text {
    display: grid;
    grid-template-areas: "media"
                         "content";

    @include breakpoint(md) {
        grid-template-areas: "content media";
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &--half {
            // Default state
        }

        &--wide {
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
        }

        &--short {
            grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
        }

        &--reverse {
            grid-template-areas: "media content";
    
            &.image-text--wide {
                grid-template-columns: 2fr 1fr;
            }
    
            &.image-text--short {
                grid-template-columns: 1fr 2fr;
            }
        }
    }

    &__content {
        @extend %content-vertical-spacing;
        @extend %content-horizontal-spacing;

        grid-area: content;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content-inner {
        display: flex;
        flex-direction: column;
        color: var(--t-content);
    }

    &__media {
        grid-area: media;
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100vh;

        @include breakpoint(md) {
            position: sticky;
            top: 0;
        }
    }

    &__picture {
        position: relative;
        display: block;
        height: 100%;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}