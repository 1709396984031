/* ======================================== */
/* select */
/* ======================================== */

@use 'abstracts' as *;

select {

    > option {
        font-family: $font-stack;
        font-style: normal;
    }
    
}
