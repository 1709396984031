/* ======================================== */
/* Menu */
/* ======================================== */

@use 'sass:color';
@use "abstracts" as *;

.menu-layout {
    overflow: clip;         // Do not change this.
    position: relative;
    padding: 10rem 2rem;

    display: grid;
    grid-template-columns: minmax(0, 1fr); // Prevent grid blow-out
    grid-template-areas: 
            "aside"
            "main"
            "nav" ;

    @include breakpoint(md) {
        grid-template-columns: 1fr 350px;
        grid-template-areas: 
            "main aside"
            "nav nav";
        gap: 0 5rem;
    }

    &__main {
        grid-area: main;
        display: flex;
        flex-direction: column;
        row-gap: 2rem;

        @include breakpoint(sm) {
            row-gap: 4rem;
        }
    }

    &__aside {
        grid-area: aside;

        &--hide-mobile {
            display: none;

            @include breakpoint(md) {
                display: block;
            }
        }
    }

    &__sticky {

        @include breakpoint(md) {
            position: sticky;
            top: 5rem;
            display: flex;
            
            &--align-content {
                justify-content: center;
            }
        }
    }

    &__options-nav {
        display: flex;
        flex-wrap: wrap;
        gap: 5rem;
        margin-bottom: 5rem;

        @include breakpoint(md) {
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 3rem;
            margin-bottom: 0;
            max-height: calc(100vh - 200px); // 200px = Top main nav and Bottom menu nav
            overflow-y: auto;
        }
    }
}

.menu-bg-image {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    // background-image - inline style

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: color.scale($black, $alpha: -20%);;
    }
}
