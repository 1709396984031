/* ======================================== */
/* quote */
/* ======================================== */

@use 'abstracts' as *;

.quote {
    position: relative;
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    max-width: 75ch;
    text-align: center;

    @include breakpoint(sm) {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    > blockquote {
        display: block;
        position: relative;
        // quotes: "“" "”";
        margin-bottom: 2rem;
    }

    &__content {
        font-family: $font-stack;
        color: rgb(var(--t-content));
        font-size: clamp(1.125rem, 2vw, 2rem);
    }

    &__author {
        color: rgb(var(--t-content));

        &-name {
            font-weight: bold;
        }
    }

    &__description {
        color: rgb(var(--t-content));
    }

    &__image {
        border-radius: 100%;
        margin-bottom: 1.5rem;
    }
}
.quote-icon {
    $icon-position: 2rem;
    content: "";
    display: block;
    position: absolute;

    &--left {
        top: -#{$icon-position};
        left: -#{$icon-position};
    }

    &--right {
        bottom: -#{$icon-position*2};
        right: -#{$icon-position};
    }

    .theme-icon-content-stroke {
        fill: rgb(var(--t-content));
    }
}
