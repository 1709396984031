/* ======================================== */
/* Menu Options */
/* ======================================== */

@use "abstracts" as *;

.menu-options {

    &__label {
        display: inline-block;
        margin-bottom: 3em;
        padding: .25em 1em;
        font-family: $font-stack-baskerville;
        font-size: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        background-color: rgba(var(--t-content), 0.1);
        color: rgba(var(--t-content));
    }

    &__list {
        display: block;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__dish {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        
        &:not(:last-child)::after {
            content: "";
            position: relative;
            top: 1.25em;
            display: block;
            height: 1px;
            width: 3rem;
            background-color: rgba(var(--t-content));
        }
    }

    &__cost {
        margin-bottom: 0.25em;
    }

    &__price {
        font-family: $font-stack-baskerville;
        font-size: clamp(1rem, 3.5vw, 1.5rem);;
        font-weight: bold;
    }

    &__note {
        font-family: $font-stack-baskerville;
        font-size: clamp(.75rem, 3.5vw, 1rem);
        font-style: italic;
        color: rgba(var(--t-content), 0.75);
    }

    &__name {
        font-family: $font-stack;
        font-size: clamp(.75rem,3.5vw,1.25rem);
        margin-bottom: 0.5em;
    }

    &__summary {
        font-family: $font-stack-baskerville;
        font-weight: normal;
        font-size: clamp(.75rem,3.5vw,1rem);
    }
}