/* ======================================== */
/* Signpost */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$signpost-transition-duration: 0.3s;
$signpost-gutter: 2rem;

.signpost-reveal {

    &__heading {
        font-size: 1.75rem;
        margin-bottom: 0;
        line-height: 1;

        @include breakpoint(sm) {
            font-size: 2rem;
        }

        @include breakpoint(mx) {
            font-size: 2.5rem;
        }
    }

    &__target {
        z-index: 100;
        position: fixed;
        display: flex;
        align-items: flex-start;
        top: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(var(--t-bg), 0.95);
        color: rgb(var(--t-content));
        overflow: auto;

        // Visually hidden for toggle
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s 0.3s, opacity 0.3s 0s;

        &.popout-is-active {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s 0s, transform 0.3s 0s;
        }

        &-inner {
            position: relative;
            z-index: 1;
            margin: auto;
            display: block;
            width: 100%;
            @extend %content-vertical-spacing;
            @extend %content-horizontal-spacing;
            // max-width: $max-content-width;
            padding-top: calc(#{$content-block-letterbox--xs} + 50px + 1rem);

            @each $breakpoint, $size in $content-block-letterbox-sizes {
                @include breakpoint($breakpoint) {
                    padding-top: calc(#{$size} + 50px + 2rem);
                }
            }
        }
    }

    &__close {
        position: fixed;
        right: 2rem;
        top: 5rem;
        z-index: 2;
        width: 1rem;
        border: none;
        background-color: transparent;
        cursor: pointer;
        
        svg {
            width: 100%;
    
            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-out;
            }
        }

        &:hover,
        &:focus {

            svg {
    
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__btn {
        $btn-size: 1.25rem;
        $btn-position: math.div($btn-size, 2);

        display: inline-block;
        background: transparent;
        border-top: none;
        border-right: none;
        border-bottom: solid 2px rgb(var(--t-content));
        border-left: none;
        color: rgb(var(--t-content), 0.5);
        font-size: #{$btn-size * 2};
        padding: 0.25rem 0;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover, &:focus {
            color: rgb(var(--t-content), 1);
        }

        &::after {
            content: "";
            display: inline-block;
            height: 1rem;
            width: 1rem;
            border-right: 2px solid;
            border-bottom: 2px solid;
            border-color: rgb(var(--t-content));
            transform: rotate(45deg) translateY(-$btn-position);
            margin: 0 1rem;
        }
    }
}

// Detailed view
.signpost-list-detailed {
    display: grid;
    gap: 2rem;
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(sm) {
        grid-template-columns: (repeat(2, 1fr));
        row-gap: 4rem;
    }

    @include breakpoint(md) {
        grid-template-columns: 1fr;
        gap: 6rem;
    }
}

.signpost-detailed {
    display: flex;
    flex-direction: column;
    background: var(--t-bg);
    gap: 4rem;

    @include breakpoint(md) {
        justify-content: center;
        flex-direction: row;
        width: 90%;

        &:nth-child(odd) {
            padding-left: 10%;
            flex-direction: row-reverse;
        }
        
        &:nth-child(even) {
            padding-right: 10%;
        }
    }

    // Text styles
    &__content {

        align-self: flex-start;

        @include breakpoint(sm) {
            align-self: flex-end;
        }
        
        @include breakpoint(md) {
            flex-grow: 1;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        @extend %shift-heading;
        font-family: $font-stack-alt;
        text-transform: capitalize;
    }

    &__text {
        margin-bottom: 2rem;
    }

    // Image Styles
    &__media {
        @include breakpoint(md) {
            flex-basis: 50%;
            flex-grow: 1;
            flex-shrink: 0;
        }
    }

    &__picture {
        display: block;
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // height: auto;
        // width: 100%;

        // @include breakpoint(md) {
        //     width: auto;
        // }
    }
}

// Compact view
.signpost-list-compact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
}

.signpost-compact {
    display: inline-flex;
    align-items: center;
    gap: 1rem;

    .signpost-reveal & {
        // Waypoints don't play well with scrollable area;
        opacity: 1;
        transform: none;
    }

    &:hover,
    &:focus {

        .signpost-compact__content {
            color: rgb(var(--t-link--neutral));
        }

        .signpost-compact__icon {
            > svg {
                .theme-icon-bg {
                    // code
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-content--action));
                }
                .theme-icon-content-fill {
                    // code
                }
            }
        }
    }

    &__content {
        display: inline-block;
        transition: all $signpost-transition-duration 0s ease-out;
        color: rgb(var(--t-link--neutral));
    }

    &__heading {
        margin: 0;
        font-family: $font-stack;
        font-size: clamp(1.25rem,1.5vw,2rem);
    }

    &__icon {
        display: flex;
        flex-shrink: 0;
        width: 2rem;

        > img,
        > svg {
            width: 100%;
        }

        > svg {
            .theme-icon-bg {
                // fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                // fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }
}

// Detaild - No Image view
.signpost-list-detailed-no-image {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    align-items: stretch;

    .layout--fullwidth & {

    }
}

.layout--fullwidth {
    .signpost-list--detailed-no-image {
        grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
}

.signpost-card {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: rgb(var(--t-bg--action));
    color: rgb(var(--t-content));
    border: solid 1px rgba(var(--t-content), 0);
    transition: all $signpost-transition-duration 0s ease-out;

    &:hover,
    &:focus {
        background-color: rgb(var(--t-bg--action));
        color: rgb(var(--t-content));
        border: solid 1px rgba(var(--t-content), 1);
        transform: translateY(-3px);

        .signpost-card__icon {
            transform: translateX(1rem);

            > svg {
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__title {
        font-size: 0.75rem;
        margin-bottom: 1.5rem;
    }

    &__heading {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        font-family: $font-stack-alt;
        text-transform: capitalize;
        font-size: clamp(3.5rem,4vw,4rem);
        font-weight: normal;
    }

    &__summary {

    }

    &__icon {
        display: block;
        width: 2rem;
        margin-top: auto;
        transition: transform 0.3s 0s ease-out;

        > img,
        > svg {
            width: 100%;
        }

        > svg {
            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }
}