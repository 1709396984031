/* ======================================== */
/* button */
// <button> element
/* ======================================== */

@use 'abstracts' as *;

$form-button-border-thickness: 1px;
$form-button-border-radius-size: 0;
$form-button-chevron-thickness: 0.175em;
$form-button-transition-timing: 0.3s;

.FormBuilder {
    button,
    input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        position: relative;
        display: inline-flex;
        flex-shrink: 0;
        background-color: rgba(var(--t-btn-bg));
        color: rgb(var(--t-btn-border));

        padding: 0.75rem 1.5rem 0.65rem 1.5rem;
        border-radius: $form-button-border-radius-size;
        border-style: solid;
        border-width: $form-button-border-thickness;
        border-color: rgb(var(--t-btn-border));
        text-decoration: none;
        // box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.15);
        font-family: $font-stack;
        font-weight: 700;
        font-size: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 0.125em;

        align-items: center;
        justify-content: space-between;
        transition: all $form-button-transition-timing 0s ease-in-out;
        overflow: hidden;
        
        &:hover,
        &:focus {
            background-color: rgba(var(--t-btn-content));
            color: rgba(var(--t-content));
            border-color: rgb(var(--t-content));
        }
    }

    .button-icon {
        background: transparent; // Reset
        border: none; // Reset
        display: flex;
        align-items: center;
        gap: 0.5rem;
            
        svg {
            width: 100%;

            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-out;
            }
        }

        &:hover,
        &:focus {

            .button-icon__text {
                color: rgb(var(--t-linkbutton--action));
            }

            svg {

                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }

        &__text {
            font-family: $font-stack-alt;
            color: rgb(var(--t-linkbutton));
            font-weight: normal;
            transition: color 0.3s 0s ease-out;
        }

        &__icon {
            width: 2rem;
        }
    }

    //-------------------------------------------------
    // Submit icon (used to replace submit input button

    .submit-icon {
        width: 2rem;
        height: 2rem;
        cursor: pointer;

        svg {

            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.1s 0s ease-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.1s 0s ease-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.1s 0s ease-out;
            }
        }

        &:hover,
        &:focus {

            svg {
                        
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }

        input[type="submit"] {
            display: none;
        }
    }

    .input-inner-icon {
        position: absolute;
        right: 0.5em;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    //-------------------------------------------------
    // Upload button

    input[type="file"] {
        max-width: 100%;
        color: rgba(var(--t-content), 1);
        padding: 1em;
        background: rgba(var(--t-bg), 1);
        border-radius: 0.25em;
        border: 1px dashed;
        border-color: rgba(var(--t-content), 1);
        font-size: 1rem;
    
        &::file-selector-button {
            margin-right: 1em;
            border: none;
            background: rgba(var(--t-btn-bg), 1);
            color: rgba(var(--t-btn-content), 1);
            border: none;
            padding: 0.75rem 1.5rem 0.65rem 1.5rem;
            border-radius: 0;
            cursor: pointer;
            font-family: $font-stack;
            text-transform: uppercase;
            letter-spacing: 0.125em;
            transition: background .2s ease-in-out;
    
            &:hover {
                background: rgba(var(--t-btn-bg), 1);
                color: rgba(var(--t-btn-content), 1);
            }
        }
    }
}