@use "abstracts" as *;

// $box-shadow: 0px 5px 5px 5px #e5e5e5;

// Intro
.profile-wrapper {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
}

// Profile Container
.profile-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    grid-auto-flow: dense; // Do not change this.
    gap: 1rem;

    @include breakpoint(sm) {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
}

/* Layout - fullwidth show different amount of columns */
.layout--fullwidth {
    .profile-list {
    
        @include breakpoint(mx) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

// Styles for Card
.card-profile {
    display: grid;
    cursor: pointer;
    border: none; // Override base button
    background: none;  // Override base button

    animation-fill-mode: backwards;

    &.no-expander {
        cursor: auto;
    }

    &.is-inactive {
        opacity: 1;

        .card-profile__figure {
            opacity: 0.75;
            transform: scale(0.8);
        }
    }

    &:hover,
    &:focus {
        .card-profile__inner {
            // border-color: rgb(var(--t-standout-bg));
            // box-shadow: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.1);
        }

        .card-profile__figure {

            img {
                transform: scale(1.05);
            }
        }

        .theme-icon-bg { fill: rgb(var(--t-standout-linkbutton-icon-bg--action)); }
        .theme-icon-content-stroke { stroke: rgb(var(--t-standout-linkbutton-icon-content--action)); }
        .theme-icon-content-fill { fill: rgb(var(--t-standout-linkbutton-icon-content--action)); }
    }

    &.is-active {

        &:focus {
            .card-profile__inner {
                background-color: rgb(var(--t-standout-bg));
                color: rgb(var(--t-standout-content));
            }
        }

        &.card-triangle::after {
            display: block;
            content: "";
            position: absolute;
            left: calc(50% - 1rem);
            bottom: -1.1rem;
            width: 1.75rem;
            height: 1rem;
            background-color: rgb(var(--t-standout-bg));
            clip-path: polygon(50% 0%, 10% 100%, 90% 100%);

            transform: translateY(35%);
            opacity: 0;

            animation-name: move-up;
            animation-duration: 0.25s;
            animation-delay: 0.65s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

            @include breakpoint(sm) {
                bottom: -2.1rem;
            }
        }
    }

    &__inner {
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        height: 100%;
        background-color: rgb(var(--t-bg--action));
        color: rgb(var(--t-content));
        border: 2px solid transparent;
        border-radius: 0.25rem;
        transition: all 0.3s 0s ease-in-out;
        
        &--landscape {
            flex-direction: row;
            text-align: left; 
        }

        @include breakpoint(sm) {
            flex-direction: column;
            text-align: center;
            padding: 2rem;
        }
    }

    &__img-wrapper {

    }

    &__figure {
        flex-shrink: 0;
        transition: all 0.3s 0s ease-in-out;
        overflow: hidden;
        border-radius: 50%;
        width: 4rem;

        @include breakpoint(sm) {
            width: 100%;
        }

        > picture {
            width: 100%;
            display: block;
        }

        img {
            width: 100%;
            display: block;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
        }
    }

    &__img-fallback {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: rgb(var(--t-bg));

        @include breakpoint(lg) {
            width: 200px;
            height: 200px;
        }

        svg {
            width: 35px;
        }

        .theme-icon-stroke {
            stroke: rgb(var(--t-standout-bg));
        }
    }

    &__info {
        display: flex;
        width: 100%;
        flex-direction: column;
        text-align: left;

        @include breakpoint(sm) {
            text-align: center;
            gap: 0.5rem;
        }
    }

    &__title,
    &__position {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        margin-bottom: 0;
        font-weight: 900;
    }

    &__position {
        font-weight: 400;
    }

    &__cta-icon {
        flex-shrink: 0;
        position: relative;
        width: 2rem;
        margin-left: auto;

        @include breakpoint(sm) {
            margin-top: auto;
        }
        

        > svg {
            width: 100%;

            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                stroke: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }
}

// Hovers etc. only when there is content to expand
.card-profile:not(.no-expander) {
    
    .card-profile:hover, .card-profile:focus {
        .card-profile {

            &__img {
                img {
                    transform: scale(1.1);
                }
            } 
        }
    }
}

.card-profile-expander {
    display: none;
    position: relative;
    align-items: flex-start;
    padding: 2rem 1rem;
    box-shadow: 0px 5px 5px 5px rgb(var(--t-content-shadow));
    background-color: rgb(var(--t-standout-bg));
    color: rgb(var(--t-standout-content));
    transform: translateZ(0);
    gap: 1rem;

    opacity: 0;
    transform: scale(0.5);

    @include breakpoint(sm) {
        padding: 2rem;
    }

    @include breakpoint(md) {
        padding: 4rem;
        gap: 2rem;
    }

    &.is-active {
        display: flex;
        grid-column: 1 / -1;

        animation-name: expand;
        animation-duration: 0.5s;
        animation-delay: 0.25s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &__inner {
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: $max-content-width;
    }

    &__btn-close {
        flex-shrink: 0;
        background: none;
        border: none;
        position: sticky;
        top: 2rem;
        margin-left: auto;
        width: 2rem;

        svg {
            width: 100%;

            .theme-icon-bg { fill: rgba(var(--t-standout-linkbutton-icon-bg), 0.05); }
            .theme-icon-content-stroke { stroke: rgb(var(--t-standout-linkbutton-icon-content)); }
            .theme-icon-content-fill { fill: rgb(var(--t-standout-linkbutton-icon-content)); }
        }

        &:hover,
        &:focus {
            cursor: pointer;

            .theme-icon-bg { fill: rgba(var(--t-standout-linkbutton-icon-bg--action)); }
            .theme-icon-content-stroke { stroke: rgb(var(--t-standout-linkbutton-icon-content--action)); }
            .theme-icon-content-fill { fill: rgb(var(--t-standout-linkbutton-icon-content--action)); }
        }
    }


    .social-media {
        margin-bottom: 1rem;
    
        &__link {
    
            .theme-icon-bg {
                fill: rgb(var(--t-standout-content));
            }

            &:hover,
            &:focus {
    
                .theme-icon-bg {
                    fill: rgb(var(--t-highlight));
                }
            }
        }
    }
}

// Animation for Opend Card
@keyframes expand {
    0% {
        transform: scale(0.75);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

// Animation for Inactive Cards
@keyframes inactive {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.75;
        transform: scale(0.95);
    }
}

// Triangle Animation
@keyframes move-up {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}