/* ======================================== */
/* Block Advanced */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.block-advanced {
    width: 100%;
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include breakpoint(sm) {
        row-gap: 4rem;
    }



    &__top,
    &__intro,
    &__aside,
    &__body {
        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__top {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        row-gap: 2rem;
        margin-bottom: 2rem;

        @include breakpoint(sm) {
            flex-direction: row;
            row-gap: 2rem;
            column-gap: 4rem;
            margin-bottom: 0;
        }
    }

    &__intro,
    &__aside {
        @include breakpoint(sm) {
            flex-basis: calc(50% - 2rem);
            position: sticky;
            top: 2rem;
        }
    }

    &__intro {

        + .block-advanced__aside {
            @include breakpoint(sm-max) {
                margin-top: 2rem;
            }

        }
    }

    &__aside {
        // Code
    }

    &__body {
        width: 100%;
    }
}