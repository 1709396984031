/* ======================================== */
/* Beverage */
/* ======================================== */

@use "abstracts" as *;


.drink-category {
    font-family: $font-stack-alt;
    font-size: 4rem;

    &.depth-1 {
        font-size: 3rem;
        text-align: right;
    }
}

.drink-list {
    
    @include breakpoint(sm) {
        margin-left: 2rem;
    }
    
    &__heading {
        margin-bottom: 2rem;
        font-weight: bold;

        @include breakpoint(md) {
            margin-bottom: 4rem;
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.drink {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.5rem;

    // Left column
    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        gap: 0 0.5em;
        max-width: 35ch;
    }

    &__name {

    }

    &__note {
        color: rgba(var(--t-content), 0.5);
        font-size: 16px;
    }

    &__summary {
        flex-basis: 100%;
        font-size: 0.8em;
    }

    // Right column
    &__details {
        display: flex;
        gap: 2rem;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: 0;
        font-size: 16px;
        font-family: $font-stack-baskerville;
        text-transform: capitalize;
    }   

    &__size {
        max-width: 20ch;
    }

    &__vintage {
        max-width: 20ch;
    }

    &__price {
        max-width: 5ch;
    }


}

// Aside Navigation
.beverage-nav {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 100px);
    overflow-y: auto; // allow scrollbars if item list takes more space
    padding-left: 2rem;
    margin-left: 4rem;

    &__item {
        position: relative;
        width: fit-content;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        color: rgba(var(--t-content), 0.5);
        transition: all 0.3s ease-in-out;

        &.active {
            color: rgba(var(--t-content), 1);
            
            &:before {
                content: "";
                position: absolute;
                left: -1.5rem;
                display: block;
                width: 0.75rem;
                height: 1px;
                background: rgb(var(--t-content));
                top: 0.45rem;
            }
        }

        &:hover, &:focus {
            color: rgba(var(--t-content), 1);
        }

        &.depth-0 {
            
        }

        &.depth-1 {
            margin-left: 1.5rem;
            display: none;
        }

        &.depth-2 {
            margin-left: 3rem;
            display: none;
        }
    }
}

// Open state
.parent ~ .open {
    display: block;
}

// Depths 
// Dont put any code inside depth, it must be nested. eg. .parent .depth-1 { }
.depth-0 {}
.depth-1 {}
.depth-2 {}
