/* ---------------------------------------------------------------------------------*/
/*  SITE SEARCH
/* ---------------------------------------------------------------------------------*/

@use 'abstracts' as *;

.site-search-overlay {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.6s, opacity 0.6s 0s, background-color 0.6s 0s;
    z-index: 60;
    background: rgba(var(--t-bg), 0.95);

    @include breakpoint(md) {
        box-shadow: 0 0px 100px 10px rgb(0 0 0 / 10%);
    }
    
    &.search-is-active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.6s 0s, background-color 0.6s 0s;
    }
}

.site-search-overlay__close { // Overriding base button styles here which is always fun
    margin-left: auto;
    z-index: 101;
    padding: 0;
    background: transparent;
    border: none;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.3s, opacity 0.3s 0s;

    .search-is-active & {
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s 0s;
    }

    > .icon-text {
        margin-right: 1em;
    }
}

.site-search-overlay__content {
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    transform: scale(0.9);
    transition: transform 0.6s 0s, opacity 0.6s 0s;
    opacity: 0;

    .search-is-active & {
        opacity: 1;
        transition: transform 0.6s 0.3s, opacity 0.6s 0.3s;
        transform: scale(1);
    }
}

/* Trigger in header */

.search-trigger {
    display: flex;
    align-items: center;
    gap: 0.25em;
    border: none; // Override base button style
    background: none; // Override base button style
    cursor: pointer;


    &:hover,
    &:focus {

        .search-trigger__text {
            color: rgb(var(--t-linkbutton--action));   
        }

        .search-trigger__icon {

            svg {
                        
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }

    &__text {
        display: none;
        font-weight: bold;
        font-size: 1.125rem;

        @include breakpoint(xs) {
            display: block;
        }     
    }

    &__icon {
        width: 2rem;

        > svg {
            width: 100%;

            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0);
                transition: fill 0.1s 0s ease-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.1s 0s ease-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.1s 0s ease-out;
            }
        }
    }

    &--mobile {
        margin-left: auto;

        @include breakpoint(md) {
            display: none;
        }

        .search-trigger__text {
            font-size: 0.75rem;
            text-transform: uppercase;
            font-family: $font-stack;
            font-weight: normal;
        }

        .search-trigger__icon {
            width: 1.125rem;
        }

        &:hover,
        &:focus {

            .search-trigger__icon {

                > svg {
                
                    .theme-icon-content-fill {
                        fill: rgb(var(--t-link));
                    }
                    .theme-icon-content-stroke {
                        stroke: rgb(var(--t-link));
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------------------------------*/
/*  SITE SEARCH RESULTS
    Base SearchEngine module styling has not been rendered out in templates.
    This is custom styling based on markup
/* ---------------------------------------------------------------------------------*/

.search-results {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    width: 100%;

    &__heading {
        color: rgb(var(--t-content--action));
    }

    &__heading,
    &__summary {
        // text-align: center;
    }

    &__list {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;

        &-item {
            border-bottom: 1px solid rgba(var(--t-content), 0.1);
            margin-bottom: 0;

            &:first-child {
                border-top: 1px solid rgba(var(--t-content), 0.1);
            }
        }
    }
}

/* Search result */

.search-result {
    display: block;
    padding: 2em 0;

    &__path {
        opacity: 0.5;
    }

    &__link {
        transition: color 0.2s 0s;
    }

    &__desc {
        padding: 1rem 0 0 0;
    }

    &__highlight {
        font-weight: bold;
        padding: 0 0.25em;
        border-radius: 0.25em;
        background: rgb(var(--t-bg--action));
    }
}

/* Search results pager */

.search-results-pager {
    padding-top: 2rem;
    width: 100%;
    display: flex;

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 2rem auto;
        max-width: 100%;
    }

    &__list-item,
    &__separator {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__list-item {
        margin: 0 2px 2px 0;
    }

    &__item {
        // border-radius: $cp-border-radius/2;
        text-decoration: none;
        padding: 0 0.5em;
        display: inline-block;
    }

    &__item--current {
        text-decoration: none;
    }

    &__separator {
        display: inline-block;
        padding: 0 .5rem;
    }
}


/* --------------------------------------------------------------------------------------------*/
/* BLOCK CONTENT SINGLE COLUMN */
/* --------------------------------------------------------------------------------------------*/

.block-site-search {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

    &--center {
        justify-content: center;
    }

    &__inner {
        width: 100%;
        max-width: 35em;
        display: flex;
        flex-direction: column;

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

.site-search {
    position: relative;
}