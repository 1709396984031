.social-media {
    display: flex;
    align-items: center;

    &--stacked {
        flex-direction: column;
    }

    &__link {
        margin-right: 1rem;
        width: 1.5rem;

        &:last-child {
            margin-right: 0;
        }

        > svg {
            width: 100%;
            height: auto;

            .theme-icon-bg {
                fill: rgb(var(--t-content));
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-bg));
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-bg));
            }
        }

        &:hover {

            > svg {
    
                .theme-icon-bg {
                    fill: rgb(var(--t-icon-bg));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-icon-content));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-icon-content));
                }
            }

        }
    }
}



