/* ======================================== */
/* switch */
/* ======================================== */

$switch-width: 50px;
$switch-size: 28px;

.switch-group {
    display: flex;
    align-items: center;

    span {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: $switch-width;
    height: $switch-size;
    margin-bottom: initial;
    
    input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
}
  
.switch__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.2s;
    border-radius: $switch-size;

    &:before {
        position: absolute;
        content: "";
        height: $switch-size*0.75;
        width: $switch-size*0.75;
        left: $switch-size*0.125;
        bottom: $switch-size*0.125;
        transition: 0.2s;
        border-radius: 50%;
    }
}
  
.switch__input {

    &:checked + .switch__slider {

        &:before {
            transform: translateX(#{$switch-size*0.75});
        }
    }
}