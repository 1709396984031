/* ======================================== */
/* range-slider */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$range-width: 100% !default;
$range-handle-size: 20px !default;
$range-track-height: 2px !default;

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: $range-track-height;
    border-radius: 0;
    outline: none;
    padding: 0;
    margin: 0;

    // Range Handle
    &::-webkit-slider-thumb,
    &::-moz-range-thumb {
        appearance: none;
        width: $range-handle-size;
        height: $range-handle-size;
        border-radius: 50%;
        border: none;
        cursor: pointer;
        transition: all .15s ease-in-out;
    }
}

.range-slider {
    position: relative;

    @include breakpoint(sm) {
        top: math.div($range-handle-size, 2); // Need to offset by the height of the handle
    }
}

.range-slider__feedback {
    display: flex;
    justify-content: space-between;
    margin: 0;
}

.range-slider__feedback-key {
    font-weight: 600;
}

.range-slider__feedback-value {
    font-weight: 100;
    text-align: right;
    min-width: 6em;
}