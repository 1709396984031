/* ======================================== */
/* block signposts */
/* ======================================== */

@use "abstracts" as *;
@use 'sass:math';

.block-signposts {
    
}

.signpost-tile {
    position: relative;
    display: flex;
    color: rgb(var(--t-content));
    background-color: rgb(var(--t-bg));
    overflow: clip;

    @include breakpoint(sm) {
        // align-items: center;
    }

    &:hover,  &:focus {
        color: rgb(var(--t-content));

        .signpost-tile__icon {
    
            svg {
    
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-content));
                }
            }
        }

        .signpost-tile__img {
            opacity: 0.75;
            transform: scale(1.05);
        }
    }

    // Media Layer
    &__media {
        position: absolute;
        inset: 0;
        z-index: 1;
        background-color: rgb(var(--t-bg));

        &:after {
            content: "";
            position: absolute;
            inset: 0;
            display: block;
        }
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform-origin: center center;
        transition: transform 1s 0s ease-out, opacity 0.2s 0s ease-out;
    }

    // Content Layer
    &__content {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: flex-end;
        width: 100%;

        @include breakpoint(md) {
            text-align: center;
        }
    }

    &__body {
        position: sticky;
        bottom: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 2rem;
        padding-top: 6rem;
        background: linear-gradient(
            0deg, 
            rgba(0, 0, 0, 0.6) 0%,
            rgba(0, 0, 0, 0.3) 50%,
            rgba(0, 0, 0, 0.15) 65%,
            rgba(0, 0, 0, 0.075) 75.5%,
            rgba(0, 0, 0, 0.037) 82.85%,
            rgba(0, 0, 0, 0.019) 88%,
            rgba(0, 0, 0, 0) 100%
        );

        @include breakpoint(md-max) {
            padding-right: 6rem;
        }

        @include breakpoint(md) {
            align-items: center;
            padding: clamp(2rem, 4vw, 4rem) clamp(4rem, 7vw, 8rem);
        }
    }

    &__body-content {
        @include breakpoint(mx) {

        }
    }

    &__title {
        font-family: $font-stack-alt;
        margin-bottom: 0.125em;
        text-transform: capitalize;
	    font-size: clamp(3rem,5vw,7rem);
        line-height: 1.125;

        @include breakpoint(lg) {
            font-size: clamp(3rem,7vw,7rem);
        }
    }

    &__text {
        flex-grow: 1;
        font-weight: 600;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        position: absolute;
        bottom: clamp(2rem, 4vw, 4rem);
        right: clamp(2rem, 4vw, 4rem);
        padding-top: 6rem;
        height: 100%;
        display: flex;
        align-items: flex-end;
        width: 2rem;

        svg {
            width: 100%;

            .theme-icon-content-stroke {
                stroke: rgb(var(--t-content));;
            }
        }
    }

    &__sticky-icon {
        z-index: 1;
        align-self: flex-end;
        position: sticky;
        bottom: clamp(2rem, 4vw, 4rem);
        margin-top: clamp(2rem, 4vw, 4rem);
        transform: translateY(20%);
    }
}

/* Layout cases: */
$signpost-height-mobile: 133vw;
$spacing-mobile: 3rem;

// Mobile gaps around signpost
.mobile-spacing {

    @include breakpoint(sm-max) {
        gap: $spacing-mobile;
        .signpost-tile:nth-child(odd) {
            margin-right: $spacing-mobile;
        }
        .signpost-tile:nth-child(even) {
            margin-left: $spacing-mobile;
        }
    }
}

// 1 Large image
.signpost-tile-grid-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min(#{$signpost-height-mobile}, 100vh); // Keep the ratio but dont go above 100vh

    @include breakpoint(sm) {
        grid-template-rows: min(62vw, 100vh); // 1st row: Keep the ratio but dont go above 100vh;
    }

    .signpost-tile__content {
        @include breakpoint(sm) {
            align-items: center;
        }
    }

    @include breakpoint(sm) {

        .signpost-tile {
            &__body {
                justify-content: center;
                align-items: center;
                padding-right: clamp(4rem, 7vw, 8rem); // reset
            }
        
            &__content {
                height: 100%;
            }
            
            &__body {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: radial-gradient(
                    circle closest-side, 
                    rgba(0,0,0,.3) 0%, 
                    rgba(0,0,0,.3) 30%,
                    rgba(0,0,0,0) 80%
                );

                .signpost-body-content {
                    text-align: center;
                }
            }
        }
    }
}

// 2 Equal columns 
.signpost-tile-grid-2 {
    display: grid;
    grid-auto-rows: minmax($signpost-height-mobile, auto); // Keep the ratio but grow if more space is needed

    @extend .mobile-spacing;
    
    @include breakpoint(sm) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min(62vw, calc(100vh - 4rem));
        margin-bottom: math.div(-$content-block-letterbox--sm, 2);

        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--sm, 2);
        }
    }
    @include breakpoint(md) {
        margin-bottom: math.div(-$content-block-letterbox--md, 2);
        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--md, 2);
        }
    }
    @include breakpoint(lg) {
        margin-bottom: math.div(-$content-block-letterbox--lg, 2);
        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--lg, 2);
        }
    }
    @include breakpoint(mx) {
        margin-bottom: math.div(-$content-block-letterbox--mx, 2);
        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--mx, 2);
        }
    }
}

// 2 Columns - first items spans two rows
.signpost-tile-grid-3 {
    display: grid;
    grid-auto-rows: minmax($signpost-height-mobile, auto);

    @extend .mobile-spacing;

    @include breakpoint(sm) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min(31vw, calc(100vh - 4rem));
        margin-bottom: math.div(-$content-block-letterbox--sm, 2);

        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--sm, 2);
            grid-row: span 2;
        }
    }
    @include breakpoint(md) {
        margin-bottom: math.div(-$content-block-letterbox--md, 2);
        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--md, 2);
        }
    }
    @include breakpoint(lg) {
        margin-bottom: math.div(-$content-block-letterbox--lg, 2);
        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--lg, 2);
        }
    }
    @include breakpoint(mx) {
        margin-bottom: math.div(-$content-block-letterbox--mx, 2);
        .signpost-tile:nth-child(1) {
            top: math.div(-$content-block-letterbox--mx, 2);
        }
    }
}

// 4 Signposts:
// 1st signpost is large - spans 2 columns and 2 rows
// 2nd signpost spans 2 rows
// 3rd and 4th same size
.signpost-tile-grid-4 {
    display: grid;
    grid-auto-rows: minmax($signpost-height-mobile, auto);

    @extend .mobile-spacing;

    @include breakpoint(sm) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min(62vw, calc(100vh - #{$content-block-letterbox--sm}));
        margin-bottom: math.div(-$content-block-letterbox--sm, 2);

        .signpost-tile:nth-child(odd) {
            top: math.div(-$content-block-letterbox--sm, 2);
        }
    }

    @include breakpoint(md) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--md}));
        grid-template-rows: min(62vw, 100vh); // 1st row: Keep the ratio but dont go above 100vh;
        margin-bottom: math.div(-$content-block-letterbox--md, 2);

        .signpost-tile:nth-child(odd) {
            top: 0;
        }

        .signpost-tile:nth-child(1) {
            grid-column: span 2;

            justify-content: center;
            align-items: center;

            .signpost-tile {
                
                &__content {
                    height: 100%;
                }
                
                &__body {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: radial-gradient(
                        circle closest-side, 
                        rgba(0,0,0,.3) 0%, 
                        rgba(0,0,0,.3) 30%,
                        rgba(0,0,0,0) 80%
                    );
                }
            }

        }

        .signpost-tile:nth-child(2) {
            grid-row: span 2;
            top: math.div(-$content-block-letterbox--md, 2);
        }
    }

    @include breakpoint(lg) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--lg}));
        margin-bottom: math.div(-$content-block-letterbox--lg, 2);

        .signpost-tile:nth-child(2) {
            top: math.div(-$content-block-letterbox--lg, 2);
        }
    }

    @include breakpoint(mx) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--mx}));
        margin-bottom: math.div(-$content-block-letterbox--mx, 2);

        .signpost-tile:nth-child(2) {
            top: math.div(-$content-block-letterbox--mx, 2);
        }
    }
}

// 5 or more signpost items - simple grid all items equal size
.signpost-tile-grid-odd {
    display: grid;
    grid-auto-rows: minmax($signpost-height-mobile, auto);

    @extend .mobile-spacing;

    @include breakpoint(sm) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min(62vw, 100vh);
        grid-auto-rows: min(62vw, calc(100vh - 4rem));
        margin-bottom: math.div(-$content-block-letterbox--sm, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--sm, 2);
        }

        .signpost-tile:nth-child(1) {
            grid-column: span 2;

            justify-content: center;
            align-items: center;

            .signpost-tile {
                
                &__content {
                    height: 100%;
                }
                
                &__body {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    background: radial-gradient(
                        circle closest-side, 
                        rgba(0,0,0,.3) 0%, 
                        rgba(0,0,0,.3) 30%,
                        rgba(0,0,0,0) 80%
                    );
                }
            }

        }
    }

    @include breakpoint(md) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--md}));
        margin-bottom: math.div(-$content-block-letterbox--md, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--md, 2);
        }
    }

    @include breakpoint(lg) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--lg}));
        margin-bottom: math.div(-$content-block-letterbox--lg, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--lg, 2);
        }
    }

    @include breakpoint(mx) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--mx}));
        margin-bottom: math.div(-$content-block-letterbox--mx, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--mx, 2);
        }
    }
}

.signpost-tile-grid-even {
    display: grid;
    grid-auto-rows: minmax($signpost-height-mobile, auto);

    @extend .mobile-spacing;

    @include breakpoint(sm) {
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min(62vw, calc(100vh - #{$content-block-letterbox--sm}));
        margin-bottom: math.div(-$content-block-letterbox--sm, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--sm, 2);
        }
    }

    @include breakpoint(md) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--md}));
        margin-bottom: math.div(-$content-block-letterbox--md, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--md, 2);
        }
    }

    @include breakpoint(lg) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--lg}));
        margin-bottom: math.div(-$content-block-letterbox--lg, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--lg, 2);
        }
    }

    @include breakpoint(mx) {
        grid-auto-rows: min(31vw, calc(100vh - #{$content-block-letterbox--mx}));
        margin-bottom: math.div(-$content-block-letterbox--mx, 2);

        .signpost-tile:nth-child(even) {
            top: math.div(-$content-block-letterbox--mx, 2);
        }
    }
}

.z-index-top {
    z-index: 3;
}

[class^="signpost-tile-grid-"] {
    // Add gap only on desktop, mobile has its own gaps.
    @include breakpoint(sm) {
        gap: 0px; // Trying without any gap just now as there is more content in place.
    }
}
