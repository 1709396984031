/* ======================================== */
/* article card */
/* ======================================== */

@use "abstracts" as *;
@use 'sass:color';

.article-card {
    display: block;
    position: relative;
    text-decoration: none;
    background-color: rgb(var(--t-bg--action));
    color: rgb(var(--t-content));
    transition-property: background-color, color;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: ease-in-out;

    &:hover,
    &:focus {
        // color: rgb(var(--t-content));
        background-color: rgb(var(--t-standout-bg));
        color: rgb(var(--t-standout-content));

        .article-card {

            &__image {
                transform: scale(1.1);
            }

            &__cta {
                    
                .theme-icon-bg {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }

        .tag-list__tag {
            background-color: rgb(var(--t-standout-bg--action));
        }

        .logo-placeholder-theme-bg {
            fill: rgb(var(--t-standout-content));
        }
    }

    &__media {
        width: 100%;
        margin: 0;
        position: relative;
    }

    &__picture {
        width: 100%;
        overflow: hidden;
        display: block;
    }

    &__image {
        width: 100%;
        transition: transform 0.3s 0s ease-in-out;
        display: block;
    }
    
    &__content {
        position: relative;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > *:first-child { margin-top: 0;}
        > *:last-child { margin-bottom: 0;}
    }

    &__type {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        font-size: 0.5rem;
        padding: 0.625em 1em 0.5em 1em;
        font-family: $font-stack-alt;
        line-height: 1;
        background: rgb(var(--t-highlight-bg));
        color: rgb(var(--t-highlight-content));
        border-radius: 0 0 0 0.25em;
        text-transform: uppercase;
        letter-spacing: 0.125em;
        transition: all 0.3s 0s ease-in-out;
    }

    &__tags {
        margin-top: auto;
        padding-bottom: 1rem;
    }

    .tag-list__tag {
        background: rgb(var(--t-bg));
    }

    &__heading {
        font-weight: bold;
    }

    &__summary {
        margin-bottom: 1rem;
    }

    &__location {
        margin: 0;
    }

    &__meta {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: auto 0 1rem 0;

        p {
            margin: 0;
        }
    }

    &__date {
        flex-shrink: 0;
        flex-grow: 0;

        &-day {
            font-size: 2rem;
            line-height: 1;
            font-weight: bold;
            display: block;
        }
        
        &-month-year {
            font-size: 0.75rem;
            line-height: 1;
            text-transform: uppercase;
            display: block;
        }

        &-divider {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            font-size: 2rem;
        }
    }

    &__cta {
        flex-grow: 0;
        width: 2rem;
        margin-left: auto;

        > img,
        > svg {
            width: 100%;
            height: auto;
        }

        .theme-icon-bg {
            fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-fill {
            fill: rgb(var(--t-linkbutton-icon-content));
            transition: fill 0.3s 0s ease-out;
        }
        .theme-icon-content-stroke {
            stroke: rgb(var(--t-linkbutton-icon-content));
            transition: stroke 0.3s 0s ease-out;
        }
    }
}

.articles-grid {
    // @extend %content-vertical-spacing;
    display: grid;
    gap: 2rem;

    @include breakpoint(sm) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @include breakpoint(md) {
        grid-template-columns: 1fr;
        gap: 4rem;

        &__item:nth-child(odd) {
            .article-signpost {
                padding-right: 10%;
            }
        }
        
        &__item:nth-child(even) {
            .article-signpost {
                flex-direction: row-reverse ;
                padding-left: 10%;
            }
        }
    }
}

.article-signpost {
    display: flex;
    flex-direction: column;
    background: var(--t-bg);
    gap: 2rem;
    position: relative;

    @include breakpoint(md) {
        flex-direction: row;
        align-items: center;
        width: 90%;
    }

    // Image styles below
    &__media {
        flex-shrink: 0;
        flex-basis: 50%;
    }

    &__picture {

    }

    &__image {
        display: block;
        max-width: 100%;
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    // Content styles below
    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include breakpoint(sm-only) {
            padding-left: 0;
            padding-right: 0;
        }

        > * { margin-bottom: 1em; }
        > *:first-child { margin-top: 0;}
        > *:last-child { margin-bottom: 0;}
    }

    &__tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    &__tag {
        text-transform: uppercase;
        padding: 0.25em 0.5em 0.1em 0.5em;
        font-size: 0.625rem;
        letter-spacing: 1px;
        background-color: rgba(var(--t-content), 0.05);
        color: var(--t-tag-content);
    }

    &__date {
        text-transform: uppercase;
        font-size: 0.75rem;
        font-weight: bold;
        letter-spacing: 1px;
    }

    &__text {
        
        > *:last-child { margin-bottom: 0;}

        @include breakpoint(md){
            > * { font-size: 1.5rem; }
        }
    }
}