/* ======================================== */
/* loader */
/* ======================================== */

@use 'abstracts' as *;

.loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    // background-color: rgba(255, 255, 255, 0.25);
    display: none;
    mix-blend-mode: exclusion;
}

.loader--full-page {
    position: fixed;

    @include breakpoint(md) {
        width: calc(100vw - #{$sidebar-width});
        left: auto;
        right: 0;
    }
}

.loader__inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* --------------------------------------------------------------------------------------------*/
/* AJAX LOADER */

.ajax-loader,
.ajax-loader:after {
    border-radius: 50%;
    width: 7rem;
    height: 7rem;
}

.ajax-loader {
    margin: 4rem auto;
    font-size: 1rem;
    position: relative;
    text-indent: -9999em;
    border-width: 0.25rem;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 0.25) rgba(255, 255, 255, 1);
    transform: translateZ(0);
    animation: loadingSpinner 1.1s infinite linear;
}

@-webkit-keyframes loadingSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}