/* ======================================== */
/* content prominent */
/* ======================================== */

@use 'abstracts' as *;

.content-prominent {
    // font-weight: 600;
    font-size: clamp(1.25rem, 4vw, 2rem);
    line-height: 1.25;

    @include breakpoint(sm) {
        // font-weight: 400;
    }
}