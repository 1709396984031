/* ======================================== */
/* textbox */
/* ======================================== */

@use 'abstracts' as *;

input[type=text],
input[type=url],
input[type=tel],
input[type=number],
input[type=color],
input[type=email],
input[type=password],
.textbox,
select,
textarea {
    display: block;
    -webkit-appearance: none;
    // background-color: rgb(var(--t-form-input-bg));
    background-color: rgba(var(--t-form-input-bg), 0.05);
    border-color: rgba(var(--t-form-input-border), 0);
    color: rgba(var(--t-form-input-content), 1);
    padding: $input-padding*0.75 $input-padding;
    width: 100%;
    font-family: $font-stack;
    font-size: $form-font-size;
    line-height: 1.25em;
    border-width: $input-border-width;
    border-style: solid;
    border-radius: $input-border-radius;
    transition: all 0.2s 0s ease-in-out;

    &:hover,
    &:focus {
        background: rgba(var(--t-form-input-bg--action), 0.15);
        border-color: rgba(var(--t-form-input-border--action), 1);
        color: rgba(var(--t-form-input-content--action), 1);
        outline: none;
    }

    &:invalid {
        box-shadow: none !important;
    }

    // Placeholders need to be defined separately for each browser.
    // If paired together certain browsers ignore the whole thing.

    .placeholder {
        color: rgb(var(--t-form-input-content));
        font-family: sans-serif !important;
        opacity: 1 !important;
    }

    /* WebKit, Blink, Edge */
    &::-webkit-input-placeholder { @extend .placeholder; }
    /* Mozilla Firefox 4 to 18 */
    &:-moz-placeholder { @extend .placeholder; }
    /* Mozilla Firefox 19+ */
    &::-moz-placeholder { @extend .placeholder; }
    /* Internet Explorer 10-11 */
    &:-ms-input-placeholder { @extend .placeholder; }
    /* Microsoft Edge */
    &::-ms-input-placeholder { @extend .placeholder; }
    /* Most modern browsers support this now. */
    &::placeholder { @extend .placeholder; }
}