/* ======================================== */
/* image-placeholder */
/* ======================================== */

@use 'abstracts' as *;

.image-placeholder {
    position: relative;
    background-color: rgb(var(--t-standout-bg));

    &__content {
        position: relative;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 100%;

        > svg {
            position: absolute;
            top: 50%;
            width: 3rem;
            transform: translateY(-50%);
        }
    }

    .logo-placeholder-theme-bg {
        fill: rgb(var(--t-standout-content));
        transition: fill 0.3s 0s ease-in-out;
    }
}