/* ======================================== */
/* Block Form */
/* ======================================== */

@use 'abstracts' as *;

.block-form {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    display: block;
    width: 100%;

    &__heading {
        margin-bottom: 0.5em;
        @extend %shift-heading;
    }
}