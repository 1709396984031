/* ======================================== */
/* gallery */
/* ======================================== */

@use 'abstracts' as *;

.gallery {
    margin-top: 2rem;
    margin-bottom: 2rem;

    &__carousel-wrapper {
        position: relative;
        // overflow: hidden;
        width: 100%;

        @include breakpoint(md) {
            // width: calc(100vw - 20px);            // Scrollbars
            // transform: translateX(-50%);
            // margin-left: 50%;
        }
    }

    &__carousel {
        display: block;
        width: 100%;
    }

    &__carousel-item {
        opacity: 0.1;
        transition: all 0.5s 0.125s ease-in-out;

        &.slick-current {
            opacity: 1;
        }
    }

    &__carousel-item-inner {
        margin-right: 1.875rem;
    }

    &__figure {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__controls {
        padding-left: $layout-gutter--xs;
        padding-right: $layout-gutter--xs;

        position: absolute;
        top: 50%;
        width: 100vw;
        left: 50%;
        margin-left: -50vw;
        display: flex;
        justify-content: space-between;
    }

    &__carousel-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        background-color: rgba($white, 0.05);
        border: none;
        z-index: 1;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        @include breakpoint(sm) {
            width: 3.5rem;
            height: 3.5rem;
        }
    
        &.slick-disabled {
            opacity: 0;
        }

        .theme-icon-content-stroke {
            stroke: rgb(var(--t-content));
        }

        &:hover, &:focus {
            background-color: rgba($white, 0.15);
        }
    }

    .slick-list {
        overflow: visible !important;
    }
}

// Cover the casse for contained layout - shrink down slider to keep in within block.
.layout--contained {
    .gallery {

        &__carousel {
            width: 100%;
        }
    }
}
