/* ======================================== */
/* Block Parallax */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.parallax {
    width: 100%;
    display: block;
    position: relative;

    &--landscape {

        @include breakpoint(lg) {
            display: grid;
            grid-template-columns: [c-line-1] math.percentage(math.div(8, 12)) [c-line-2] math.percentage(math.div(2, 12)) [c-line-3] math.percentage(math.div(2, 12)) [c-line-4];
            grid-template-rows: [r-line-1] auto [r-line-2];
            align-items: center;

            .layout--fullwidth & {
                grid-template-columns: [c-line-1] math.percentage(math.div(6, 12)) [c-line-2] math.percentage(math.div(2, 12)) [c-line-3] math.percentage(math.div(2, 12)) [c-line-4] math.percentage(math.div(2, 12)) [c-line-5];
            }
        }
    }


    &--square {

        @include breakpoint(lg) {
            display: grid;
            grid-template-columns: [c-line-1] math.percentage(math.div(6, 12)) [c-line-2] math.percentage(math.div(2, 12)) [c-line-3] math.percentage(math.div(4, 12)) [c-line-4];
            grid-template-rows: [r-line-1] auto [r-line-2];
            align-items: center;

            .layout--fullwidth & {
                grid-template-columns: [c-line-1] math.percentage(math.div(5, 12)) [c-line-2] math.percentage(math.div(3, 12)) [c-line-3] math.percentage(math.div(2, 12)) [c-line-4] math.percentage(math.div(2, 12)) [c-line-5];
            }
        }
    }

    &--portrait {

        @include breakpoint(lg) {
            display: grid;
            grid-template-columns: [c-line-1] math.percentage(math.div(4, 12)) [c-line-2] math.percentage(math.div(1, 12)) [c-line-3] math.percentage(math.div(7, 12)) [c-line4-];
            grid-template-rows: [r-line-1] auto [r-line-2];
            align-items: center;

            .layout--fullwidth & {
                grid-template-columns: [c-line-1] math.percentage(math.div(4, 12)) [c-line-2] math.percentage(math.div(1, 12)) [c-line-3] math.percentage(math.div(4, 12)) [c-line-4] math.percentage(math.div(3, 12)) [c-line-5];
            }
        }
    }

    &-wrapper {
        width: 100%;
        position: relative;

        .layout--stretched & {
            @extend %content-vertical-spacing;
            // @extend %content-horizontal-spacing;
        }
    }

    &__content {
        opacity: 0.9;
        
        @include breakpoint(lg) {
            grid-column-start: c-line-2;
            grid-column-end: c-line-4;
            grid-row-start: r-line-1;
            grid-row-end: r-line-2;
            transform: translateY(-10vh); // Negative offset scrollme position so it hits centre at centre point of screen
            z-index: 2;
        }

        &-inner {
            padding: 2rem;
            background: rgb(var(--t-bg));
            color: rgb(var(--t-content));
            box-shadow: .25rem .25rem 2rem 0 rgba(0, 0, 0, .1);

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(lg-max) {
                transform: none !important; // Cancel out scrollme animation
            }
            
            @include breakpoint(lg) {
                padding: 3rem;
            }

            h1.primary-heading,
            h2.primary-heading {

                @include breakpoint(sm) {
                    font-size: 1.75rem; 
                }
                @include breakpoint(lg) {
                    font-size: 1.75rem; 
                }
            }
        }
    }

    &__media {

        @include breakpoint(lg) {
            grid-column-start: c-line-1;
            grid-column-end: c-line-3;
            grid-row-start: r-line-1;
            grid-row-end: r-line-2;
        }

        &-picture {
            width: 100%;
            display: block;
            position: relative;
        }

        &-img {
            width: 100%;
            display: block;
        }
    }
}