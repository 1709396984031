/* ======================================== */
/* mixins */
/* ======================================== */

@use 'abstracts/variables/breakpoint' as *;
@use 'abstracts/variables/layout' as *;

/* Responsive Detection */
@mixin breakpoint($media) {

	// Max, min and specific breakpoint media queries

	@if $media == xs-max {
		@media only screen and (max-width: ($xs - 1)) { @content }
	}
	@else if $media == sm-max {
		@media only screen and (max-width: ($sm - 1)) { @content }
	}
	@else if $media == md-max {
		@media only screen and (max-width: ($md - 1)) { @content }
	}
	@else if $media == lg-max {
		@media only screen and (max-width: ($lg - 1)) { @content }
	}
	@else if $media == mx-max {
		@media only screen and (max-width: ($mx - 1)) { @content }
	}


	@if $media == xs {
		@media only screen and (min-width: $xs) { @content }
	}
	@else if $media == sm {
		@media only screen and (min-width: $sm) { @content }
	}
	@else if $media == md {
		@media only screen and (min-width: $md) { @content }
	}
	@else if $media == lg {
		@media only screen and (min-width: $lg) { @content }
	}
	@else if $media == mx {
		@media only screen and (min-width: $mx) { @content }
	}
	

	@if $media == xs-only {
		@media only screen and (min-width: $xs) and (max-width: ($sm - 1)) { @content }
	}
	@else if $media == sm-only {
		@media only screen and (min-width: $sm) and (max-width: ($md - 1)) { @content }
	}
	@else if $media == md-only {
		@media only screen and (min-width: $md) and (max-width: ($lg - 1)) { @content }
	}
	@else if $media == lg-only {
		@media only screen and (min-width: $lg) and (max-width: ($mx - 1)) { @content }
	}
	@else if $media == mx-only {
		@media only screen and (min-width: $mx) { @content }
	}
};


// This range only
@mixin breakpoint-range($media-from, $media-top) {
    @media only screen and (min-width: $media-from) and (max-width:  $media-top) { @content }
};

/* -----------------------------*/
/* SPACING EXTENSIONS */
/* -----------------------------*/

// y - Axis
%content-vertical-spacing {
	padding-top: $content-block-letterbox--xs;
	padding-bottom: $content-block-letterbox--xs;

	@each $breakpoint, $size in $content-block-letterbox-sizes {
        @include breakpoint($breakpoint) {
            padding-top: $size;
            padding-bottom: $size;
        }
    }
}

// x - Axis
%content-horizontal-spacing {
	padding-left: $content-block-pillarbox--xs;
	padding-right: $content-block-pillarbox--xs;

	@each $breakpoint, $size in $content-block-pillarbox-sizes {
        @include breakpoint($breakpoint) {
            padding-left: $size;
            padding-right: $size;
        }
	}
}

/* -----------------------------*/
/* INDENT HEADING */
/* -----------------------------*/
%shift-heading {
	margin-left: -0.25em;
}