
/* ======================================== */
/* Share This */
/* ======================================== */

@use 'abstracts' as *;

// Modal - Fullscreen
.modal {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    position: fixed;
    inset: 0 0 0 #{$sidebar-width};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    transition: opacity .6s 0s, background-color .6s 0s;
    // opacity .6s 0s, background-color .6s 0s;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }

    &__inner {
        display: block;
        overflow-y: auto;
        background-color: rgb(var(--t-bg));
        border: solid 1px rgba(0, 0, 0, 0.05);
        box-shadow: 0.375rem 0.375rem 1rem 0rem rgba(0, 0, 0, 0.15);
        opacity: 0;
        box-sizing: border-box;
        transition: opacity .15s ease-in .3s;

        .is-open & {
            opacity: 1;
        }
    }

    &__btn-close {
        display: block;
        position: sticky;
        top: 1rem;
        margin: 1rem 1rem 0 auto;
        width: 40px;
        height: 40px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        .theme-icon-bg {
            fill: rgba(var(--t-linkbutton-icon-bg), .05);
            transition: fill .3s ease-out 0s;
        }

        .theme-icon-content-stroke {
            stroke: rgb(var(--t-linkbutton-icon-content));
            transition: stroke .3s ease-out 0s;
        }

        &:hover,
        &:focus {

            transform: scale(1.15);

            .theme-icon-bg {
                fill: rgb(var(--t-linkbutton-icon-bg--action));
            }
            
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content--action));
            }
        }
    }

    &__content {
        padding: 1rem 4rem;
    }

    &--fullscreen {
        padding: 0;
        background-color: rgb(var(--t-bg));

        .modal__inner {
            border:none;
            box-shadow: none;
        }
    }
}


/* ---------- Modal Helper Classes ---------- */
body.modal-helper {
    overflow: hidden;
}

// Place modal above side nav
main.modal-helper {
    // z-index: 99;
}

header.modal-helper {
    z-index: 1;
}