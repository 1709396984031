/* ======================================== */
/* footer */
/* ======================================== */

@use 'abstracts' as *;

$spacing: 4rem;

.footer {
    width: 100%;
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: clamp(2rem, 2.5vw, 4rem);

    &__logo {
        display: block;
        width: 100%;
        
        > svg {
            max-width: 12rem;
        }
    }

    &__heading {
        display: block;
        width: 100%;

        @include breakpoint(sm) {
            grid-column: span 2;
        }
    }

    &__content {
        max-width: 55ch;
   
        @include breakpoint(md) {
            flex-basis: calc(60% - 4rem);
            flex-grow: 1;
        }
    }

    &__aside {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        @include breakpoint(md) {
            flex-basis: 30%;
            max-width: 100%;
            flex-grow: 1;
        }        
    }

    ul {
        padding: 0;

        li {
            list-style: none;
            margin: 0;
        }
    }
}

.footer-foot {
    display: block;
    width: 100%;
    font-size: 0.75rem;

    p {
        font-size: 0.75rem;
    }

    a {
        color: rgb(var(--t-link--neutral));

        &:focus,
        &:hover {
            color: rgb(var(--t-link));
        }
    }
}

.utility {
    width: 100%;

    @include breakpoint(sm) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__nav {
        ul {
            padding: 0;
            margin-bottom: 1rem;

            @include breakpoint(sm) {
                display: flex;
                flex-wrap: wrap;
            }

            li {
                list-style: none;
                padding-right: 1rem;

                a {
                    // font-family: $font-stack-alt;
                }
            }
        }
    }
}

.made-by {
    a {
        font-family: $font-stack;
    }
}