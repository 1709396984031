// ----------------------------------------
// site color variables
// add custom site color variables here
// implement in theme.scss
// ----------------------------------------

@use 'sass:color';

// Assign your custom colours as root variables

// Main Theme Colours
$white: #ffffff;
$white_rgb: 255, 255, 255;

$black: #000000;
$black_rgb: 0, 0, 0;

$dark: #161D1F;
$dark_rgb: 22, 29, 31;

$light: #F2F2F2;
$light_rgb: 242, 242, 242;

// Brand Colors

$silver: #888888;
$silver_rgb: 136, 136, 136;

$earth: #AC977C;
$earth_rgb: 172, 151, 124;

$gold: #AB7B17;
$gold_rgb: 171, 123, 23;

// State

$red: #AB1D17;
$red_rgb: 171, 29, 23;