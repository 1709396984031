.cta {
    display: flex;
    width: 100%;

    &__wrapper {
        flex-grow: 1;
        padding: 1rem;
    }

    &__heading {
        color: rgb(var(--t-content));
    
    }

    &__content {
        color: rgb(var(--t-content));
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7rem;
        min-height: 10rem;

        svg {
            fill: rgb(var(--t-content));
            width: 3rem;
            height: 3rem;
        }
    }
}