/* ======================================== */
/* btn */
// looks like a button but isn't a <button> element
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$button-border-thickness: 1px;
$button-border-radius-size: 0;
$button-chevron-thickness: 0.175em;
$button-transition-timing: 0.3s;
// $button-transition-timing: 1s;

.btn {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    background-color: rgb(var(--t-btn-bg));
    padding: 0.75rem 1.5rem 0.65rem 1.5rem;
    border-radius: $button-border-radius-size;
    border-style: solid;
    border-width: $button-border-thickness;
    border-color: rgb(var(--t-btn-border));
    text-decoration: none;
    font-family: $font-stack;
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: uppercase;

    align-items: center;
    justify-content: space-between;
    transition: all $button-transition-timing 0s ease-in-out;
    overflow: hidden;

    span {
        position: relative;
        z-index: 2;
        color: rgb(var(--t-btn-content));
        transition: all $button-transition-timing 0s ease-in-out;
        line-height: 1.5em;
        letter-spacing: 0.15em;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        border-radius: $button-border-radius-size 0 0 $button-border-radius-size;
        background-color: rgb(var(--t-btn-border));
        transition: all $button-transition-timing 0s ease-in-out;
    }

    &:hover,
    &:focus {

        &:before {
            width: 100%;
            left: 100%;
            border-radius: $button-border-radius-size;
            border-color: rgb(var(--t-btn-bg--action));
            background-color: rgb(var(--t-btn-bg--action));
            // transform: scale(1.05);
        }

        span {
            color: rgb(var(--t-btn-content--action));
        }
    }
}