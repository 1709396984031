/* ======================================== */
/* theme */
// Colours only
/* ======================================== */

@use 'sass:color';
@use '../abstracts' as *;

// All css variables must be output as 3 RGB properties separated by commas for correct output
// Example: 255, 255, 255

// ----------------------------------------
// white them and :root

.theme-bg-white {
    --bg-color: #{$white_rgb};
}
:root,
.theme-white {
    // DEFAULT
        // background
        --t-bg:                                                     #{$white_rgb};

            --t-bg--action:                                         #{$light_rgb};
        // Highlight
        --t-highlight:                                              #{$silver_rgb};
        // Content
        --t-content:                                                #{$black_rgb};
            --t-content--action:                                    #{$silver_rgb};
        // link
        --t-link:                                                   #{$black_rgb};
        --t-link--neutral:                                          #{$black_rgb};

        --t-link--alt-action:                                       #{$silver_rgb};

        --t-link-bg:                                                #{$white_rgb};
            --t-link--action:                                       #{$white_rgb};
            --t-link-bg--action:                                    #{$silver_rgb};
        // link button - link with icon
        --t-linkbutton:                                             #{$black_rgb};
        --t-linkbutton-icon-bg:                                     #{$white_rgb};
        --t-linkbutton-icon-content:                                #{$black_rgb};
            --t-linkbutton--action:                                 #{$black_rgb};
            --t-linkbutton-icon-bg--action:                         #{$light_rgb};
            --t-linkbutton-icon-content--action:                    #{$black_rgb};
        // button
        --t-btn-bg:                                                 transparent;
        --t-btn-border:                                             #{$black_rgb};
        --t-btn-content:                                            #{$white_rgb};
            --t-btn-bg--action:                                     transparent;
            --t-btn-border--action:                                 #{$black_rgb};
            --t-btn-content--action:                                #{$black_rgb};
        // icon
        --t-icon-bg:                                                #{$black_rgb};
        --t-icon-content:                                           #{$white_rgb};

        // mask
        --t-mask:                                                   #{$white_rgb};

    // STANDOUT
        --t-standout-bg:                                            #{$light_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($light, $lightness: -20%)), 
            color.green(color.scale($light, $lightness: -20%)), 
            color.blue(color.scale($light, $lightness: -20%));
        --t-standout-bg--action:                                    #{$t-standout-bg--action_rgb};

        --t-standout-content:                                       #{$black_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                    #{$white_rgb};
        --t-standout-linkbutton-icon-bg:                            #{$white_rgb};
        --t-standout-linkbutton-icon-content:                       #{$black_rgb};
            --t-standout-linkbutton--action:                        #{$silver_rgb};
            --t-standout-linkbutton-icon-bg--action:                #{$white_rgb};
            --t-standout-linkbutton-icon-content--action:           #{$black_rgb};

    // FORM
        --t-form-input-bg:                                          #{$black_rgb};
        --t-form-input-content:                                     #{$black_rgb};
        --t-form-input-border:                                      #{$black_rgb};

            --t-form-input-bg--action:                              #{$black_rgb};
            --t-form-input-content--action:                         #{$black_rgb};
            --t-form-input-border--action:                          #{$black_rgb};

    // Picture Blend Mode
        --t-pic-blend-mode:                                         multiply;

    // Slider Overlay
        --t-slide-overlay:                                          rgb(#{$white_rgb}, 0.9);
}

// ----------------------------------------
// black theme

.theme-bg-black {
    --bg-color: #{$black_rgb};
}
.theme-black {
    // DEFAULT
        // background
        --t-bg:                                                     #{$black_rgb};
            --t-bg--action:                                         #{$dark_rgb};
        // Highlight
        --t-highlight:                                              #{$silver_rgb};
        // Content
        --t-content:                                                #{$white_rgb};
            --t-content--action:                                    #{$silver_rgb};
        // link
        --t-link:                                                   #{$white_rgb};
        --t-link--neutral:                                          #{$white_rgb};

        --t-link--alt-action:                                       #{$silver_rgb};

        --t-link-bg:                                                #{$black_rgb};
            --t-link--action:                                       #{$black_rgb};
            --t-link-bg--action:                                    #{$silver_rgb};
        // link button - link with icon
        --t-linkbutton:                                             #{$white_rgb};
        --t-linkbutton-icon-bg:                                     #{$black_rgb};
        --t-linkbutton-icon-content:                                #{$white_rgb};
            --t-linkbutton--action:                                 #{$white_rgb};
            --t-linkbutton-icon-bg--action:                         #{$dark_rgb};
            --t-linkbutton-icon-content--action:                    #{$white_rgb};
        // button
        --t-btn-bg:                                                 transparent;
        --t-btn-border:                                             #{$white_rgb};
        --t-btn-content:                                            #{$black_rgb};
            --t-btn-bg--action:                                     transparent;
            --t-btn-border--action:                                 #{$white_rgb};
            --t-btn-content--action:                                #{$white_rgb};
        // icon
        --t-icon-bg:                                                #{$black_rgb};
        --t-icon-content:                                           #{$white_rgb};

        // mask
        --t-mask:                                                   #{$black_rgb};

    // STANDOUT
        --t-standout-bg:                                            #{$dark_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($dark, $lightness: -20%)), 
            color.green(color.scale($dark, $lightness: -20%)), 
            color.blue(color.scale($dark, $lightness: -20%));
        --t-standout-bg--action:                                    #{$t-standout-bg--action_rgb};

        --t-standout-content:                                       #{$white_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                    #{$black_rgb};
        --t-standout-linkbutton-icon-bg:                            #{$black_rgb};
        --t-standout-linkbutton-icon-content:                       #{$white_rgb};
            --t-standout-linkbutton--action:                        #{$silver_rgb};
            --t-standout-linkbutton-icon-bg--action:                #{$black_rgb};
            --t-standout-linkbutton-icon-content--action:           #{$silver_rgb};

    // FORM
        --t-form-input-bg:                                          #{$white_rgb};
        --t-form-input-content:                                     #{$white_rgb};
        --t-form-input-border:                                      #{$white_rgb};

            --t-form-input-bg--action:                              #{$white_rgb};
            --t-form-input-content--action:                         #{$white_rgb};
            --t-form-input-border--action:                          #{$white_rgb};

    // Picture Blend Mode
        --t-pic-blend-mode:                                         screen;

    // Slider Overlay
        --t-slide-overlay:                                          rgb(#{$black_rgb}, 0.75);
}

// ----------------------------------------
// dark theme

.theme-bg-dark {
    --bg-color: #{$dark_rgb};
}
.theme-dark {
    // DEFAULT
        // background
        --t-bg:                                                     #{$dark_rgb};
            --t-bg--action:                                         #{$dark_rgb};
        // Highlight
        --t-highlight:                                              #{$silver_rgb};
        // Content
        --t-content:                                                #{$white_rgb};
            --t-content--action:                                    #{$silver_rgb};
        // link
        --t-link:                                                   #{$white_rgb};
        --t-link--neutral:                                          #{$white_rgb};

        $t-link--alt-action_rgb:
            color.red(color.scale($white, $lightness: -20%)), 
            color.green(color.scale($white, $lightness: -20%)), 
            color.blue(color.scale($white, $lightness: -20%));
        --t-link--alt-action:                                       #{$t-link--alt-action_rgb};

        --t-link-bg:                                                #{$dark_rgb};
            --t-link--action:                                       #{$dark_rgb};
            --t-link-bg--action:                                    #{$silver_rgb};
        // link button - link with icon
        --t-linkbutton:                                             #{$white_rgb};
        --t-linkbutton-icon-bg:                                     #{$dark_rgb};
        --t-linkbutton-icon-content:                                #{$white_rgb};
            --t-linkbutton--action:                                 #{$white_rgb};
            --t-linkbutton-icon-bg--action:                         #{$black_rgb};
            --t-linkbutton-icon-content--action:                    #{$white_rgb};
        // button
        --t-btn-bg:                                                 transparent;
        --t-btn-border:                                             #{$white_rgb};
        --t-btn-content:                                            #{$dark_rgb};
            --t-btn-bg--action:                                     transparent;
            --t-btn-border--action:                                 #{$white_rgb};
            --t-btn-content--action:                                #{$white_rgb};
        // icon
        --t-icon-bg:                                                #{$dark_rgb};
        --t-icon-content:                                           #{$white_rgb};

        // mask
        --t-mask:                                                   #{$dark_rgb};

    // STANDOUT
        --t-standout-bg:                                            #{$light_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($light, $lightness: -20%)), 
            color.green(color.scale($light, $lightness: -20%)), 
            color.blue(color.scale($light, $lightness: -20%));
        --t-standout-bg--action:                                    #{$t-standout-bg--action_rgb};

        --t-standout-content:                                       #{$dark_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                    #{$dark_rgb};
        --t-standout-linkbutton-icon-bg:                            #{$dark_rgb};
        --t-standout-linkbutton-icon-content:                       #{$light_rgb};
            --t-standout-linkbutton--action:                        #{$silver_rgb};
            --t-standout-linkbutton-icon-bg--action:                #{$dark_rgb};
            --t-standout-linkbutton-icon-content--action:           #{$silver_rgb};

    // FORM
        --t-form-input-bg:                                          #{$white_rgb};
        --t-form-input-content:                                     #{$white_rgb};
        --t-form-input-border:                                      #{$white_rgb};

            --t-form-input-bg--action:                              #{$white_rgb};
            --t-form-input-content--action:                         #{$white_rgb};
            --t-form-input-border--action:                          #{$white_rgb};

    // Picture Blend Mode
        --t-pic-blend-mode:                                         screen;

    // Slider Overlay
        --t-slide-overlay:                                          rgb(#{$dark_rgb}, 0.75);
}

// ----------------------------------------
// light theme

.theme-bg-light {
    --bg-color: #{$light_rgb};
}
.theme-light {
    // DEFAULT
        // background
        --t-bg:                                                     #{$light_rgb};
            --t-bg--action:                                         #{$white_rgb};
        // Highlight
        --t-highlight:                                              #{$silver_rgb};
        // Content
        --t-content:                                                #{$black_rgb};
            --t-content--action:                                    #{$silver_rgb};
        // link
        --t-link:                                                   #{$black_rgb};
        --t-link--neutral:                                          #{$black_rgb};

        $t-link--alt-action_rgb:
            color.red(color.scale($black, $lightness: -20%)), 
            color.green(color.scale($black, $lightness: -20%)), 
            color.blue(color.scale($black, $lightness: -20%));
        --t-link--alt-action:                                       #{$t-link--alt-action_rgb};

        --t-link-bg:                                                #{$light_rgb};
            --t-link--action:                                       #{$light_rgb};
            --t-link-bg--action:                                    #{$silver_rgb};
        // link button - link with icon
        --t-linkbutton:                                             #{$black_rgb};
        --t-linkbutton-icon-bg:                                     #{$light_rgb};
        --t-linkbutton-icon-content:                                #{$black_rgb};
            --t-linkbutton--action:                                 #{$black_rgb};
            --t-linkbutton-icon-bg--action:                         #{$white_rgb};
            --t-linkbutton-icon-content--action:                    #{$black_rgb};
        // button
        --t-btn-bg:                                                 transparent;
        --t-btn-border:                                             #{$dark_rgb};
        --t-btn-content:                                            #{$white_rgb};
            --t-btn-bg--action:                                     transparent;
            --t-btn-border--action:                                 #{$dark_rgb};
            --t-btn-content--action:                                #{$dark_rgb};
        // icon
        --t-icon-bg:                                                #{$light_rgb};
        --t-icon-content:                                           #{$dark_rgb};

        // mask
        --t-mask:                                                   #{$light_rgb};

    // STANDOUT
        --t-standout-bg:                                            #{$dark_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($dark, $lightness: -20%)), 
            color.green(color.scale($dark, $lightness: -20%)), 
            color.blue(color.scale($dark, $lightness: -20%));
        --t-standout-bg--action:                                    #{$t-standout-bg--action_rgb};

        --t-standout-content:                                       #{$white_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                    #{$light_rgb};
        --t-standout-linkbutton-icon-bg:                            #{$light_rgb};
        --t-standout-linkbutton-icon-content:                       #{$dark_rgb};
            --t-standout-linkbutton--action:                        #{$silver_rgb};
            --t-standout-linkbutton-icon-bg--action:                #{$silver_rgb};
            --t-standout-linkbutton-icon-content--action:           #{$dark_rgb};

    // FORM
        --t-form-input-bg:                                          #{$black_rgb};
        --t-form-input-content:                                     #{$black_rgb};
        --t-form-input-border:                                      #{$black_rgb};

            --t-form-input-bg--action:                              #{$black_rgb};
            --t-form-input-content--action:                         #{$black_rgb};
            --t-form-input-border--action:                          #{$black_rgb};

    // Picture Blend Mode
        --t-pic-blend-mode:                                         multiply;

    // Slider Overlay
        --t-slide-overlay:                                          rgb(#{$light_rgb}, 0.9);
}

// ----------------------------------------
// imagery theme - used when the background is an image

.theme-bg-imagery {
    --bg-color: #{$black_rgb};
}
.theme-imagery {
     // DEFAULT
        // background
        --t-bg:                                                     #{$black_rgb};

            $t-bg--action_rgb:
                color.red(color.scale($black, $lightness: 75%)), 
                color.green(color.scale($black, $lightness: 75%)), 
                color.blue(color.scale($black, $lightness: 75%));
            --t-bg--action:                                         #{$t-bg--action_rgb};
        // Highlight
        --t-highlight:                                              #{$light_rgb};
        // Content
        --t-content:                                                #{$white_rgb};
            --t-content--action:                                    #{$silver_rgb};
        // link
        --t-link:                                                   #{$white_rgb};
        --t-link--neutral:                                          #{$white_rgb};

        $t-link--alt-action_rgb:
            color.red(color.scale($white, $lightness: -20%)), 
            color.green(color.scale($white, $lightness: -20%)), 
            color.blue(color.scale($white, $lightness: -20%));
        --t-link--alt-action:                                       #{$t-link--alt-action_rgb};

        --t-link-bg:                                                #{$black_rgb};
            --t-link--action:                                       #{$black_rgb};
            --t-link-bg--action:                                    #{$silver_rgb};
        // link button - link with icon
        --t-linkbutton:                                             #{$white_rgb};
        --t-linkbutton-icon-bg:                                     #{$black_rgb};
        --t-linkbutton-icon-content:                                #{$white_rgb};
            --t-linkbutton--action:                                 #{$white_rgb};
            --t-linkbutton-icon-bg--action:                         #{$dark_rgb};
            --t-linkbutton-icon-content--action:                    #{$white_rgb};
        // button
        --t-btn-bg:                                                 transparent;
        --t-btn-border:                                             #{$white_rgb};
        --t-btn-content:                                            #{$black_rgb};
            --t-btn-bg--action:                                     transparent;
            --t-btn-border--action:                                 #{$white_rgb};
            --t-btn-content--action:                                #{$white_rgb};
        // icon
        --t-icon-bg:                                                #{$black_rgb};
        --t-icon-content:                                           #{$white_rgb};

        // mask
        --t-mask:                                                   #{$black_rgb};

    // STANDOUT
        --t-standout-bg:                                            #{$light_rgb};

        $t-standout-bg--action_rgb:
            color.red(color.scale($light, $lightness: -20%)), 
            color.green(color.scale($light, $lightness: -20%)), 
            color.blue(color.scale($light, $lightness: -20%));
        --t-standout-bg--action:                                    #{$t-standout-bg--action_rgb};

        --t-standout-content:                                       #{$black_rgb};
        // link button - link with icon
        --t-standout-linkbutton:                                    #{$black_rgb};
        --t-standout-linkbutton-icon-bg:                            #{$black_rgb};
        --t-standout-linkbutton-icon-content:                       #{$white_rgb};
            --t-standout-linkbutton--action:                        #{$silver_rgb};
            --t-standout-linkbutton-icon-bg--action:                #{$black_rgb};
            --t-standout-linkbutton-icon-content--action:           #{$silver_rgb};

    // FORM
        $form-input-bg-alpha:
            color.scale($white, $alpha: -90%);
        --t-form-input-bg:                                          #{$form-input-bg-alpha};

        $form-input-content-alpha:
            color.scale($white, $alpha: -70%);
        --t-form-input-content:                                     #{$form-input-content-alpha};

        $form-input-border-lpha:
            color.scale($white, $alpha: -90%);
        --t-form-input-border:                                      #{$form-input-border-lpha};

        --t-form-input-bg--action:                                  #{$form-input-bg-alpha};
        --t-form-input-content--action:                             #{$white_rgb};
        --t-form-input-border--action:                              #{$white_rgb};

    // Picture Blend Mode
        --t-pic-blend-mode:                                         none;

    // Slider Overlay
        --t-slide-overlay:                                          rgb(#{$black_rgb}, 0.75);
}

// ========================================
// Theme Highlight
// ========================================

// ----------------------------------------
// Gold Highlight
.highlight-gold {
    // Highlight
    --t-highlight:                                                  #{$gold_rgb};
    --t-highlight-bg:                                               #{$gold_rgb};
    --t-highlight-content:                                          #{$gold_rgb};
    // link
    --t-link:                                                       #{$gold_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($gold, $lightness: -20%)), 
        color.green(color.scale($gold, $lightness: -20%)), 
        color.blue(color.scale($gold, $lightness: -20%));
    --t-link--alt-action:                                           #{$t-link--alt-action_rgb};

    --t-link-bg:                                                    #{$gold_rgb};
        --t-link--action:                                           #{$black_rgb};
        --t-link-bg--action:                                        #{$gold_rgb};
    // link button
    --t-linkbutton--action:                                         #{$gold_rgb};
    --t-linkbutton-icon-bg--action:                                 #{$gold_rgb};
    --t-linkbutton-icon-content--action:                            #{$gold_rgb};
    // button
        --t-btn-bg:                                                 transparent;
        --t-btn-border:                                             #{$gold_rgb};
            --t-btn-border--action:                                 #{$gold_rgb};
            --t-btn-content--action:                                #{$gold_rgb};
    // icon
    --t-icon-content:                                               #{$gold_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                        #{$gold_rgb};
    --t-standout-linkbutton-icon-content--action:                   #{$black_rgb};
}

// ----------------------------------------
// Earth Highlight
.highlight-earth {
    // Highlight
    --t-highlight:                                                  #{$earth_rgb};
    --t-highlight-bg:                                               #{$earth_rgb};
    --t-highlight-content:                                          #{$earth_rgb};
    // link
    --t-link:                                                       #{$earth_rgb};

    $t-link--alt-action_rgb:
        color.red(color.scale($gold, $lightness: -20%)), 
        color.green(color.scale($gold, $lightness: -20%)), 
        color.blue(color.scale($gold, $lightness: -20%));
    --t-link--alt-action:                                           #{$t-link--alt-action_rgb};

    --t-link-bg:                                                    #{$earth_rgb};
        --t-link--action:                                           #{$black_rgb};
        --t-link-bg--action:                                        #{$earth_rgb};
    // link button
    --t-linkbutton--action:                                         #{$earth_rgb};
    --t-linkbutton-icon-bg--action:                                 #{$earth_rgb};
    --t-linkbutton-icon-content--action:                            #{$earth_rgb};
    // button
        --t-btn-bg:                                                 transparent;
        --t-btn-border:                                             #{$earth_rgb};
            --t-btn-border--action:                                 #{$earth_rgb};
            --t-btn-content--action:                                #{$earth_rgb};
    // icon
    --t-icon-content:                                               #{$earth_rgb};
    // standout
    --t-standout-linkbutton-icon-bg--action:                        #{$earth_rgb};
    --t-standout-linkbutton-icon-content--action:                   #{$black_rgb};
}

