/* -------------------- Link List -------------------- */
.links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;

    /* --- Modifiers --- */
    &--stacked {
        flex-direction: column; 
    }

    &--left {
        justify-content: flex-start; // Align in Row
        align-items: flex-start; // Align in Column
    }

    &--right {
        justify-content: flex-end; // Align in Row
        align-items: flex-end; // Align in Column
    }

    &--center {
        justify-content: center; // Align in Row
        align-items: center; // Align in Column
    }

    // Prevent link from going full width.
    a {
        width: fit-content;
    }
}