/* ======================================== */
/* awards */
/* ======================================== */

@use 'abstracts' as *;

.award-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: clamp(1.5rem, 5vw, 4rem);
}

.award {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;

    @include breakpoint(xs) {
        gap: 0.5rem;
    }

    &__icons {
        display: flex;
        gap: 0.5rem;
    }

    &__icon {

        .theme-icon-content-fill {
            fill: rgb(var(--t-content));
        }

        &--rating {
            > svg {
                height: 1rem;

                @include breakpoint(xs) {
                    height: 1.5rem;
                }
            }
        }

        &--logo {
            > svg {
                height: 4rem;

                @include breakpoint(xs) {
                    height: 5rem;
                }
            }
        }
    }

    &__text {
        font-size: 0.875rem;
        line-height: 1.25;

        @include breakpoint(xs) {
            font-size: 1rem;
        }

        &__key {
            font-weight: 600;
        }

        &__value {

        }
    }
}
