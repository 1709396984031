/* ======================================== */
/* primary-navigation */
/* ======================================== */

.primary-navigation {
    display: none;
    position: absolute;
    top: 0;
    background-color: #a9a9a9;
    width: 100%;

    ul {
        list-style: none;
    }

    li  {

    }

    a {
        text-decoration: none;
    }

    &.is-visible {
        display: flex;
    }
}