/* ======================================== */
/* figure-video */
/* ======================================== */

.figure-video {
    position: relative;
    width: 100%;
    margin: 3rem 0;

    &__figcaption {
        text-align: center;
        padding-top: 0.5em;
        font-size: 0.75em;
        text-transform: uppercase;
        color: rgb(var(--t-content));
        letter-spacing: 0.15em;
    }
}
