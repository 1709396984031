/* ======================================== */
/* aspect-ratio */
/* ======================================== */

.aspect-ratio {

}

.aspect-ratio-square {
    aspect-ratio: 1/1;
}

.aspect-ratio-landscape {
    aspect-ratio: 3/2;
}

.aspect-ratio-portrait {
    aspect-ratio: 2/3;
}