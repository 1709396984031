/* ======================================== */
/* result-filter */
/* ======================================== */

@use 'abstracts' as *;

.result-filter {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    width: 100%;
    display: flex;

    flex-direction:column;
    justify-content: flex-end;
    align-items:center;
    min-height: 600px;

    @include breakpoint(md) {
        flex-wrap: wrap;
    }

    &__intro {
        width: 100%;

        h2 {
            font-size: 5rem;
            color: rgba(var(--t-content));

            @include breakpoint(lg) {
                font-size: 7rem;
            }
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__group {
        display: flex;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 1rem;
        flex-wrap: wrap;
    }

    &__item {
        display: flex;
        justify-content: center;

        @include breakpoint(xs-max) {
            width: 100%;
        }

        @include breakpoint(md) {
            flex-basis: auto;
        }
    }

    &__submit {
        flex-shrink: 0;
        flex-grow: 0;
    }

    &__item-content {
        flex-direction: column;

        @include breakpoint(md) {
            margin: 0;
        }
    }

    &__label {
        display: block;
        margin-bottom: 0.25em;
        font-weight: bold;
        letter-spacing: 1px;
    }

    &__category {
        @include breakpoint(xs) {
            min-width: 18rem;
        }
    }
}

