/* ======================================== */
/* Menu A La Carte */
/* ======================================== */

@use "abstracts" as *;

.menu-degustation {

    > * {
        font-size: clamp(1rem, 4vw, 1.25rem);
    }

    &:nth-child(even) {
        margin-left: clamp(3rem, 4.5vw, 5rem);
    }

    &:after {
        content: '';
        position: relative;
        display: block;
        height: 50px;
        
        background-repeat: no-repeat;
        background-position: left center;
    }

    // Eyebrow icon's
    &:nth-child(3n-2)::after {
        min-width: 210px;
        background-image: url('../../images/svg/icons/eyebrow-1.svg');
    }

    &:nth-child(3n-1)::after {
        min-width: 140px;
        background-image: url('../../images/svg/icons/eyebrow-2.svg');
    }

    &:nth-child(3n)::after {
        min-width: 160px;
        background-image: url('../../images/svg/icons/eyebrow-3.svg');
    }
}