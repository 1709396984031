/* ======================================== */
/* Block Reservation */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.block-reservation {
    @extend %content-vertical-spacing;
    
    width: 100%;
    display: grid;

    @include breakpoint(md) {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }

    &__content {
        @extend %content-horizontal-spacing; 

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__calendar {
        display: grid;
        place-content: center;
    }

    &__calendar-iframe-box {

        iframe {
            
        }
    }
}