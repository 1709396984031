/* ======================================== */
/* typography */
/* ======================================== */

@use '../abstracts' as *;

html {
    font-size: 1em;     // 16px (default value for browsers)
    line-height: 1.5;   // 24px
}

body {
	font-family: $font-stack;
	font-size: clamp(1rem,1.25vw,1.5rem);

	@include breakpoint(sm) {
		font-size: clamp(1.25rem,1.25vw,1.5rem);
	}
}

h1,h2,h3,h4,h5,h6,
.alpha, .beta, .gamma, .delta, .epsilon, .zeta {
 	font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0.5em 0;
}

h1, .alpha {
	line-height: 1.125;
    font-family: $font-stack-alt;
	font-size: clamp(3.5rem,7vw,7rem);
	margin-bottom: 0.125em;
	text-transform: capitalize;
}


h2, .beta {
	line-height: 1.125;
    font-family: $font-stack-alt;
	font-size: clamp(3.5rem,4vw,4rem);
	margin-bottom: 0.125em;
	text-transform: capitalize;
}

h3, .gamma {
	font-size: clamp(1.5rem,1.5vw,1.5rem);
	line-height: 1.25;
	font-family: $font-stack;
}

h4, .delta {
	font-size: 1.5rem;
	line-height: 1.25;
	font-family: $font-stack;
}

h5, .epsilon {
	font-size: 1.25rem;
	line-height: 1.25;
	font-family: $font-stack;
}

h6, .zeta {
	font-size: 1.25rem;
	line-height: 1.25;
	font-family: $font-stack;
}


p,
.p {

    &.alpha,
    &.beta,
    &.gamma,
    &.delta,
    &.epsilon,
    &.zeta {
		line-height: 1.25;
    }
}

p, li, label {
	margin: 0 0 1em 0;
	line-height: 1.5;
}

/* --------------------------------------------------------------------------------------------*/
/* SITE SPECIFIC TYPOGRAPHY */
/* --------------------------------------------------------------------------------------------*/

strong {
	font-family: $font-stack;
}

a {
	text-decoration: none;
}

sub, sup {
	font-size: 50%;
}

sup {
	top: -1em;
}

/* -----------------------------*/
/* FONT */

.text-sans-serif {
	font-family: $font-stack;
}

/* -----------------------------*/
/* PROMINENCE */

.text-highlight {
	color: rgb(var(--t-content--action));
}

.text-muted {
	opacity: 0.6;
}

/* -----------------------------*/
/* STYLE */

.text-uppercase {
	text-transform: uppercase;
}

.text-nocase {
	text-transform: none;
}

/* -----------------------------*/
/* SIZE */

.text-large {
	display: inline-block;
	line-height: 1.125em;
	font-size: 1.5em;

	@include breakpoint(md) {
		font-size: 2em;
	}
}

.text-small {
	font-size: 0.75em;
	line-height: 1em;
	display: inline-block;
}

.text-tiny {
	font-size: 0.5em;
	line-height: 1em;
	display: inline-block;
}

/* -----------------------------*/
/* WEIGHT */

.text-light {
	font-family: $font-stack;
	font-weight: 400;
}

.text-regular {
	font-family: $font-stack;
}

.text-bold {
	font-weight: bold;
    font-family: $font-stack;
}


a {
    color: rgb(var(--t-link));

    &:hover,
    &:focus {
        color: rgb(var(--t-link--alt-action));
    }
}

/* -----------------------------*/
/* UNNEXPECTED USER ERRORS */

br + br {
	display: none; // Assuming that we don't need any more than 2
}