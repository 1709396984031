/* ======================================== */
/* Block Standard */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.block-standard {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    & > *:last-child {
        margin-bottom: 0;
    }
}