/* --------------------------------------------------------------------------------------------*/
/* ACCORDION */
/* Works with custom JS toggle .jsAccordionTrigger and .jsAccordionContent */
/* --------------------------------------------------------------------------------------------*/

@use 'abstracts' as *;

$accordion-arrow-size: 40px;
$accordion-border-width: 1px;

.accordion-wrapper {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    width: 100%;
}

.accordion {
    display: block;
    margin-bottom: 3rem;

    &__item {
        border-bottom: $accordion-border-width solid rgb(var(--t-content));

        &:not(:first-child) {
            margin-top: -$accordion-border-width;
        }
    }

    &__content {
        display: none;
        padding: 1em 0;
        width: calc(100% - 4rem);

        @include breakpoint(sm) {
            padding-left: 2rem;
        }
    }
}

.accordion-trigger {
	position: relative;
    display: block;
    margin: 0; // override base button style
    padding: 1em 10px 0.5em 0; // override base button style
    width: 100%; // override base button style
    background: none;
    border: none; // override base button style
    border-radius: 0; // override base button style
    font-family: $font-stack;
    font-size: 1.5rem;
    color: rgb(var(--t-content));
    cursor: pointer;

    &:hover,
    &:focus {
        box-shadow: none; // override base button setting
        transform: none; // override base button setting
    }

    &:active {
        transform: scale(1); // Override base button style
    }

	&.is-active {
		color: var(--t-content--highlight); // Color also set in _themes.scss

		.accordion-trigger__icon {
            transform: rotate(45deg);
		}
    }

    &::before {
        display: none;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    
    &__text {
        padding-right: 1em;
        text-align: left; // Override base button style
    }
    
    &__icon {
        transition: all 0.1s 0s;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .theme-icon-content-stroke {
            stroke: rgb(var(--t-content));
        }
    }
}


