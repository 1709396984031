/* ======================================== */
/* page_article_single */
/* ======================================== */

@use "abstracts" as *;
@use 'sass:color';

$article-max-width: 65rem;

.article-intro {
    @extend %content-horizontal-spacing;
    @extend %content-vertical-spacing;

    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    max-width: $article-max-width;
    margin-left: auto;
    margin-right: auto;

    &__heading {
        font-size: 2rem;
        margin-bottom: 3rem;
        font-weight: 600;
        font-family: $font-stack;
        text-transform: none;
        font-weight: 400;

        @include breakpoint(sm) {
            font-size: 3rem;
        }
    }
}

.article-body {
    @extend %content-horizontal-spacing;
    @extend %content-vertical-spacing;
    max-width: $article-max-width;
    margin-left: auto;
    margin-right: auto;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.article-aside {
    @include breakpoint(md) {
        // Move it to side on this break point
        order: 1;

        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

.article-featured-media {
    overflow: hidden;

    &__picture {
        width: 100%;
        display: block;
    }

    &__image {
        width: 100%;
        display: block;
    }
}

// Meta - aside content
.meta-box {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;

    @include breakpoint(xs) {
        gap: 1.5rem;
        align-items: stretch;
    }

    &__item {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.75rem;

        @include breakpoint(xs) {
            border-right: 1px solid;
            padding-right: 1.5rem;
            display: flex;
            align-items: center;
    
            &:last-child {
                border-right: 0;
                padding-right: 0;
            }
        }

        &--jumpto {
            flex-basis: auto;
            flex-grow: 0;

            @include breakpoint(sm-max) {
                flex-basis: 100%;
                order: 1;
                border-right: none;
                padding-right: 0;
            }
        }
    }

    &__tag {
        width: 100%;
    }

    &__read-time {
    }

}

.meta-author {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    margin-bottom: 2rem;
    background: rgb(var(--t-standout-bg));
    color: rgb(var(--t-standout-content));

    &__avatar {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    &__image {
        width: clamp(4rem, 7vw, 8rem);
        height: clamp(4rem, 7vw, 8rem);
        border-radius: 50%;
        margin-bottom: auto;
    }

    &__details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &__name {
        font-family: $font-stack;
        font-weight: 600;
    }

    &__position {
        font-size: 0.75em;
    }

    &__summary {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-end;
        column-gap: 2rem;

        @include breakpoint(sm) {
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &__bio {
        flex-grow: 1;
        max-width: 75ch;

        & *:last-child {
            margin-bottom: 0;
        }
    }

    &__social {
        flex-shrink: 0;
        display: flex;

        @include breakpoint(sm) {
            align-items: flex-end;
        }

        svg {
    
            .theme-icon-bg {
                fill: rgb(var(--t-standout-content));
            }
        }
    }
}

// Previous & Next Article
.latest-news {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include breakpoint(sm) {
        flex-direction: row;
    }

    &__item {
        @include breakpoint(sm) {
            flex-basis: calc(50% - 1rem);
        }
    }

    &__previous {
        
    }

    &__next {
        text-align: right;
    }
}
