/* ======================================== */
/* Menu */
/* ======================================== */

@use "abstracts" as *;

.menu-nav {
    position: sticky;
    bottom: 0px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100vw; // Without this layout may break
    min-height: 4rem;
    transition: all 0.3s 0s ease-in-out;
    background-color: rgba(var(--t-bg));
    box-shadow: 0 0 1rem 1rem rgb(0, 0, 0, 1);
    margin-top: 4rem;
    // grid-column: span 2;
    grid-area: nav;
    border-radius: 5px;
    border: solid 1px rgba(var(--t-bg));

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 4rem;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0,0,0,0) 100%);
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 4rem;
        height: 100%;
        background: linear-gradient(270deg, rgba(0,0,0, 0.2) 0%, rgba(0,0,0,0) 100%);
    }

    &.is-open {
        bottom: 0;
    }

    &__inner {
        margin: 0 auto;
        display: flex;
        overflow-x: auto;
        padding: 0 $content-block-pillarbox--xs;

        /* hide scrollbar but allow scrolling */
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }

    &__item {
        margin: 0 1.5em;
        white-space: nowrap;
        color: rgba(var(--t-content), 0.5);
        transition: all 0.3s ease-in-out;
        font-size: 0.75rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.125em;

        &.is-current-page {
            color: rgba(var(--t-content), 1);
        }

        &:hover, &:focus {
            color: rgba(var(--t-content), 1);
        }
    }
}