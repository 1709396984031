/* ======================================== */
/* breadcrumb */
/* ======================================== */

@use 'abstracts' as *;

$breadcrumb-arrow-size: 0.5em;
$breadcrumb-link-spacing: 0.5rem;


.breadcrumb {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: rgb(var(--t-bg));
    overflow: hidden;

    @include breakpoint(sm-max) {
        flex-wrap: wrap;
    }

    @media only screen and (min-width: calc(#{$sidebar-width} + #{$max-content-width})) {
        border-radius: 0 0 0.25rem 0.25rem;
    }

    &__list {
        margin: 0;
        padding: 0.75rem 1.5rem;

        @include breakpoint(sm-max) {
            font-size: 0.75rem;
        }
    }
        
    &__list-item {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        vertical-align: middle;

        // Remove arrow on last item
        &:last-child .breadcrumb__link:after {
            // display: none;
        }
    }
    
    &__link {
        text-decoration: none;
        text-transform: capitalize;
        color: rgb(var(--t-content));
        padding: 0 $breadcrumb-link-spacing;

        &::after {
            content:"";
            position: relative;
            left: $breadcrumb-link-spacing;
            display: inline-block;
            vertical-align: middle;
            width: $breadcrumb-arrow-size;
            height: $breadcrumb-arrow-size;
            border: solid;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            border-color: rgb(var(--t-content));
            margin-right: 0.5em;
            margin-top: -0.2em;
        }

        &:hover {
            color: rgb(var(--t-link));
            
            &:after {
                border-color: rgb(var(--t-link));
            }
        }
    }
}

.breadcrumb-title {
    flex-grow: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: rgb(var(--t-standout-bg));

    @include breakpoint(sm-max) {
        width: 100%;
    }

    &__title {
        padding: 1em 2rem;
        color: rgb(var(--t-standout-content));
        font-family: $font-stack-alt;
        text-transform: capitalize;
        margin: 0;
        font-size: 1rem;
    }
}
