/* ======================================== */
/* datepicker */
/* ======================================== */

@use 'abstracts' as *;

.hasDatepicker {
    .ui-datepicker {
        //width: 17em;
        padding: 0; // override
        width: 100%;
        box-shadow: none;
        border: 0px solid white;
    }

    .ui-datepicker-header {
        display: flex;
        justify-content: space-between;
    }

    .ui-widget-header {
        font-weight: bold;
        border: none;
        background: white;

    }

    .ui-datepicker-title {
        display: flex;
        flex-direction: row;
        flex-basis: 100%;
        justify-content: space-between;

       
    }
    .ui-datepicker .ui-datepicker-title  select {
            padding: 1em;
            font-size: initial;
        }

    .ui-datepicker .ui-datepicker-prev {
        color: black;
        height: 100%;
        top: 0;
        bottom: 0;
        border: none; 

        .ui-icon {
            background-image: none;
            background: none;
        }

        &:hover {
            background:none;
            border:none; 
        }
    }

    .ui-datepicker .ui-datepicker-next {
        color: black;
        height: 100%;
        top: 0;
        bottom: 0;
        border:none; 

        .ui-icon {
            background-image: none;
            background: none;
        }

        &:hover {
            background:none;
            border:none; 
        }
    }

    .ui-state-default, .ui-widget-content .ui-state-default {
        background: lightgrey;
    }

    .ui-state-default a:hover {
        background-color: darkgrey;
    }

    .ui-datepicker td a.ui-state-active {
        background: black;
    }
    .ui-datepicker td a:hover {
        color: white;
        background: black;
    }

    .ui-datepicker-week-end  {
    
    }

    .ui-state-default, .ui-widget-content .ui-state-default {
        border: none;
        padding: 0.5em;
    }
    .ui-state-highlight, .ui-widget-content .ui-state-highlight {
        border: none;
        margin: 2px;
    }
    .ui-corner-all {
        border-radius: 0;
    }
}