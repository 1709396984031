/* ======================================== */
/* Contact */
/* ======================================== */

// @use 'abstracts' as *;

.contact {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-left: 3rem;

    &__address-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__item {
        position: relative;
    }

    &__icon {
        position: absolute;
        top: 0.25rem;
        left: -3rem;
        width: 1.5rem;
        height: 1.5rem;

        .theme-icon-content-fill {
            fill: rgb(var(--t-content))
        }

        .theme-icon-content-bg {
            fill: rgb(var(--t-bg))           
        }
    }
}