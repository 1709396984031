/* ======================================== */
/* Page Hero */
/* ======================================== */

@use 'abstracts' as *;

.page-hero {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    padding-top: calc(#{$content-block-letterbox--xs} + 50px + 1rem);

    @each $breakpoint, $size in $content-block-letterbox-sizes {
        @include breakpoint($breakpoint) {
            padding-top: calc(#{$size} + 50px + 2rem);
        }
    }

    position: relative;
    display: block;
    width: 100%;

    @include breakpoint(sm) {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__content {
        @include breakpoint(sm) {
            flex-grow: 1;
        }
    }

    &__heading {
        @extend %shift-heading;
    }

    &__intro {
        margin-bottom: 2rem;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__icon {
        position: relative;
        margin-left: auto;
        width: clamp(125px, 20vw, 300px);
        height: auto;
        mix-blend-mode: difference;
        z-index: 10;

        transform: translateY(calc(25% + #{$content-block-letterbox--xs}));
        margin-top: -$content-block-letterbox--xs;

        @each $breakpoint, $size in $content-block-letterbox-sizes {
            @include breakpoint($breakpoint) {
                transform: translateY(calc(25% + #{$size}));
                margin-top: -$size;
            }
        }

        @include breakpoint(sm) {
            flex-shrink: 0;
            flex-grow: 0;
        }

        svg {
            width: 100%;
            height: 100%;

            .theme-icon-content-fill {
                fill: #fff;
            }
        }
    }

    &__awards {
    }
}
