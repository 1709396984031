/* ======================================== */
/* navigation */
/* ======================================== */

@use 'abstracts' as *;

$dropdown-indicator-size: 2rem;

.navigation {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    overflow-x: scroll;
    background: $white;
    
    // &:not(.popout-is-active) {
    &:not(.naviagation-is-open) {
        // Visually hidden for toggle
        opacity: 0;
        visibility: hidden;
        transition: visibility 0s 0.3s, opacity 0.3s 0s;
        pointer-events: none;
    }

    // &.popout-is-active {
    &.naviagation-is-open {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s 0s, transform 0.3s 0s;
    }

    &__toolbar {
        @extend %content-horizontal-spacing;
        position: fixed;
        top: 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        padding-bottom: clamp(2rem, 4vw, 3.5rem);
        width: calc(100vw - 15px);
        // width: 100%;
        z-index: 3;
        mix-blend-mode: difference;
        pointer-events: none;
    }

    &__inner {
        @extend %content-horizontal-spacing;
        position: relative;
        z-index: 2;
        width: 100%;
        padding-top: 7rem;

        @each $breakpoint, $size in $content-block-letterbox-sizes {
            @include breakpoint($breakpoint) {
                padding-left: calc(#{$size} + 5rem );
            }
        }

        @include breakpoint(sm-max) {
            padding-left: $content-block-letterbox--xs;
        }

        @include breakpoint(md) {
            padding-right: 50%;
            height: 100%;
        }

        &:before,
        &:after {
            content: "";
            position: fixed;
            width: 100%;
            display: block;
            z-index: 10;
            pointer-events: none;
        }

        &:before {
            top: 0;
            left: 0;
            height: 7rem;
            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        }

        &:after {
            bottom: 0;
            left: 0;
            height: 3.5rem;
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        }
    }

    &__background {
        position: relative;
        z-index: 2;
        pointer-events: none;

        @include breakpoint(md-max) {
            margin-top: auto;
        }

        @include breakpoint(md) {
            position: fixed;
            right: 0;
            bottom: 0;
            width: 50%;
        }

        &--overlay {
                
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 20%;
                background: linear-gradient(180deg, rgb(255 255 255), rgb(255 255 255 / 0%));
                z-index: 1;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 20%;
                height: 100%;
                background: linear-gradient(90deg, rgb(255 255 255), rgb(255 255 255 / 0%));
                z-index: 2;
            }
        }
    }

    &__image {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}


// .popout-is-active {
.naviagation-is-open {
    .site-nav > ul > li {

        // -------------------------------------------------------------
        // Nav item animations
        // Animation for elements on larger screens when nav is revealed
        // -------------------------------------------------------------

        $animation-delay: 0.1s;
        $transition-speed: 0.5s;
        $n: 20;

        @for $i from 1 through $n {

            &:nth-child(#{$i}n) {
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
                transition: opacity $transition-speed #{$i * $animation-delay}, transform $transition-speed #{$i * $animation-delay};
            }
        }
        // End nav animations
    }
}

.site-nav {
    margin-bottom: 1.5rem;

    // Level 0
    > ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0;
        padding: 0;

        .dropdown-toggle {

            &.is-active {
            }
        }
        
        > li {
            position: relative;
            z-index: 2;

            // Visually hidden and redy for animation
            transform: translateX(-1em);
            opacity: 0;
            visibility: hidden;
            transition: visibility 0s 0s, opacity 0s 0s, transform 0s 0s;
        }
    }

    // Level 1
    > ul > li > ul {
        z-index: 1;
        width: 100%;
    }

    // Level 2
    > ul > li > ul > li > ul {

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    // General
    ul {
        list-style-type: none;

        @include breakpoint(md-max) {
            width: 100%;
        }

        li {
            @include breakpoint(md-max) {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                flex-wrap: wrap;
            }

            ul {
                width: 100%;
                border-left: 1px solid currentColor;
                padding: 0 0 0 1rem;
                transform: translateX(-1em);
                overflow: hidden; // Chrome appears to want this or else it adds extra space

                // Visually hidden for toggle
                height: 0;
                opacity: 0;
                visibility: hidden;
                transition: visibility 0s 0s, opacity 0s 0s, transform 0s 0s;

                &.is-active {
                    margin: 0.5rem 0 1em 0.5rem;
                    transform: translateX(0);

                    // Appears
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                    transition: visibility 0s 0s, opacity 0.6s 0s, transform 0.6s 0s;
                }

                a {
                    flex-grow: 0;
                    max-width: calc(100% - 4em);
                    margin: 0.5em 0;
                    font-size: 1rem;
                }
            }
        }
    }

    li {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        a {
            position: relative;
            color: rgb(var(--t-content));
            line-height: 1.25;
            font-size: clamp(1rem, 2.5vw, 1.5rem);
            max-width: calc(100% - #{$dropdown-indicator-size});
            transition: all 0.1s 0s ease-out;

            &:hover,
            &:focus {
                color: rgb(var(--t-link--alt-action));
            }

            > span {
                position: relative;
            }

            .icon-nav-link {
                display: flex;
                align-items: center;

                &__icon {
                    display: none;
                }
            }
        }

        &.selected > a {
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-decoration-color: currentColor;
        }        
    }
}

.site-nav--primary {
    > ul {
        gap: 0.25rem;

        >li {
            > a {
                font-family: $font-stack-alt;
                text-transform: capitalize;
                font-size: clamp(2.5rem,4vw,4rem);
            }
        }
    }
}

.site-nav--secondary {
    > ul {
        >li {
            margin-bottom: 0.5em;

            > a {
                font-size: clamp(1.25rem,1.25vw,1.5rem);
            }
        }
    }
}

.site-nav-utility {
    // code
}

.site-nav--search {
    display: flex;
    justify-content: center;

    @include breakpoint(md-max) {
        display: none;
    }
}

.dropdown-indicator {
    display: none;
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $dropdown-indicator-size;
    height: $dropdown-indicator-size;
    border: none;
    border-radius: 1.5em;
    margin: 0;
    padding: 0;
    flex-shrink: 0;
    transition: transform 0.2s 0s ease-out;
    background: transparent;
    border: 1px solid rgba($black, 0.1); // Always lightly darker than background

    &__chevron {
        display: block;
        margin: 0;
        padding: 0;
        width: 0.5em;
        height: 0.5em;
        border: 1px solid;
        border-color: transparent $black $black transparent;
        transform: translateY(-25%) rotate(45deg);
        transform-origin: center center;
    }

    &:focus,
    &.is-active {

        .dropdown-toggle__chevron {
            border-color: transparent $white $white transparent;
        }
    }

    &:hover {
        background: rgba($black, 0.2);
    }

    &:focus {
        background: $black;
    }

    &.is-active {
        background: $black;
        transform: rotate(180deg);
        transition-delay: 0s;

        &:focus {
            background: $black;
        }
    }
}
