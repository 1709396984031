/* ======================================== */
/* link-btn */
// fancier links with icons etc
/* ======================================== */

@use 'abstracts' as *;

.link-btn {
    color: rgb(var(--t-linkbutton));
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-family: $font-stack;
    line-height: 1;
    transition: color 0.3s 0s ease-in-out;

    &--down {
        .link-btn__icon {
            transform: rotate(90deg);
        }
    }

    &__text {
        
    }

    &__icon {
        width: 2em;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 0.5em;

        svg {

            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
        }
    }


    &:hover,
    &:focus {
        color: rgb(var(--t-linkbutton--action));

        .link-btn__icon {
            svg {

                .theme-icon-bg {
                    // fill
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-bg--action));
                }
            }
        }
    }
}

.link-downlaod {
    color: rgb(var(--t-linkbutton));
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-family: $font-stack;
    line-height: 1;
    transition: color 0.3s 0s ease-in-out;

    &__icon {
        width: 1em;
        flex-shrink: 0;
        flex-grow: 0;

        svg {
            width: 100%;
            transition: all 0.3s 0s ease-in-out;

            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                
            }
        }
    }

    &:hover,
    &:focus {
        color: rgb(var(--t-linkbutton--action));

        .link-downlaod__icon {
            svg {

                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content--action));
                }
            }
        }
    }
}

