/* ======================================== */
/* cta-strip */
/* ======================================== */

@use 'abstracts' as *;

.cta-strip {
    display: flex;
    width: 100%;
    color: rgb(var(--t-content));

    &__content {
        margin-left: auto;
        margin-right: auto;
        max-width: 67.5rem;
        width: 100%;
        display: block;
        text-align: center;
    }

    &__heading {
        margin-bottom: 0.25em;
        line-height: 1.25;
    }

    &__body {
        margin: 0;
    }
}