/* ======================================== */
/* Share This */
/* ======================================== */

@use 'abstracts' as *;

// Share wrapper - button or text that opens popup 
.share {
    position: relative;
    display: flex;

    &__button {
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:hover, 
        &:focus {

            .svg {
                
                &__fill {
                    fill: rgb(var(--t-link));
                }
            }
        }
    }

    &__icon {
        display: flex;
        width: 25px;
        height: auto;
    }

    .svg {
                
        &__fill {
            fill: rgb(var(--t-content));
        }
    }
}

// Share popup
.share-popup {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;

    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
    color: rgb(var(--t-content));
    opacity: 0;
    visibility: hidden;
    background-color: rgba(var(--t-bg),.95);
    transition: opacity .6s 0s, background-color .6s 0s;

    @include breakpoint(md) {
        inset: 0 0 0 #{$sidebar-width};
    }

    &.is-open {
        opacity: 1;
        visibility: visible;

        .share-popup__inner {
            opacity: 1;
            transform: scale(1);
            transition: transform .6s .3s,opacity .6s .3s;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 35em;
        width: 100%;
        margin: 0 auto;

        opacity: 0;
        transform: scale(.9);
        transition: transform .6s 0s,opacity .6s 0s;

        @include breakpoint(md) {
            padding: 4rem;
        }
    }

    &__title {
        margin-bottom: 1rem;
        color: rgb(var(--t-content--action));
    }

    &__heading {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.25rem;
        line-height: 1.25;
    }

    &__btn-close {
        background: rgb(255 255 255 / 25%);
        padding: 0.5rem;
        border-radius: 100px;
        display: flex;
        justify-content: flex-end;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        svg {
            width: 40px;
            height: auto;
        }

        .theme-icon-bg {
            fill: rgba(var(--t-linkbutton-icon-bg),.05);
            transition: fill .3s ease-out 0s;
        }

        .theme-icon-content-stroke {
            stroke: rgb(var(--t-linkbutton-icon-content));
            transition: stroke .3s ease-out 0s;
        }

        &:hover,
        &:focus {

            .theme-icon-bg {
                fill: rgb(var(--t-linkbutton-icon-bg--action));
            }
            
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content--action));
            }
        }
    }

    &__plugin {
        display: block;
    }

    &__copy-box {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        border: solid 2px;
        border-color: rgb(var(--t-content), 0.25);
        padding: .5rem .5rem .5rem 1rem;
        transition: all .3s ease-out 0s;

        &:focus,
        &:hover {
            border-color: rgb(var(--t-content));
        }
    }

    &__icon-link {
        display: flex;
    }

    &__input {
        border: none !important;
        background: transparent;
        padding: 0 1em !important;
    }

    &__btn-copy {
        padding: 0.5rem 0.75rem;
        border-radius: 0.375rem;
        background: rgb(var(--t-content), 1);
        color: rgb(var(--t-bg), 1);
        cursor: pointer;
        font-weight: bold;
        border: none;

        &:hover,
        &:focus {
            background: rgb(var(--t-content--action), 1);
        }
    }

    &__message {
        font-size: 0.75rem;
        color: rgb(var(--t-link));

        &::selection {
            background: rgba(var(--t-standout-bg), 0.4);
        }
    }
}

// Styles for plugin "sharethis"
// To add custom styles for buttons log in to dashboard https://platform.sharethis.com/
.sharethis-container {
    display: inline-block;
    min-width: 110px;
}
