/* ======================================== */
/* TinyMCE */
/* ======================================== */
.align_left {
    text-align: left;
}

.align_center {
    text-align: center;
}

.align_right {
    text-align: right;
}

.align_full {
    text-align: justify;
}