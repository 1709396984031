/* ======================================== */
/* Block Pardot Form */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.pardot-form {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing; 
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    @include breakpoint(sm) {
        row-gap: 4rem;
    }

    &__body,
    &__content,
    &__form {
        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__body {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        row-gap: 2rem;

        @include breakpoint(sm) {
            flex-direction: row;
            row-gap: 2rem;
            column-gap: 4rem;
        }
    }

    &__content,
    &__form {
        width: 100%;
        
        @include breakpoint(sm) {
            flex-basis: calc(50% - 2rem);
            position: sticky;
            top: 2rem;
        }
    }

    &__content {
        // background: yellow;
    }

    &__form {
        // background: red;
    }
}