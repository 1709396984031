/* ======================================== */
/* Menu A La Carte */
/* ======================================== */

@use "abstracts" as *;

.menu-alacarte {

    &:nth-child(even) {
        margin-left: clamp(3rem, 4.5vw, 5rem);
    }

    &__label {
        font-family: $font-stack-alt;
        text-transform: capitalize;
        font-size: clamp(2.5rem, 3.5vw, 4rem);
        margin-bottom: clamp(1rem, 3.5vw, 2rem);
    }

    &__list {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__dish {
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-family: $font-stack-baskerville;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 1em;
        font-size: clamp(0.75rem, 3.5vw, 1rem);
    }

    &__summary {
        text-transform: capitalize;
        font-family: $font-stack;
        margin-bottom: 0.25rem;
        font-size: clamp(0.75rem, 3.5vw, 1.25rem);
    }

    &__details {
        font-family: $font-stack-baskerville;
        font-size: clamp(0.75rem, 3.5vw, 1rem);
    }
}