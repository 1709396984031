/* ======================================== */
/* copyright */
/* ======================================== */

@use 'abstracts' as *;

.copyright {
    width: 100%;

    @include breakpoint(sm) {
        display: flex;
        justify-content: space-between;
    }
}