/* ======================================== */
/* Block Advanced */
/* ======================================== */

@use 'abstracts' as *;

.simple-content {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    display: block;
    width: 100%;

    &__heading {

    }

    &__intro {

    }

    &__body {
        
    }
}