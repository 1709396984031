@use '../abstracts' as *;

.primary-heading {
	@extend %shift-heading;
	color: rgb(var(--t-content));

	.text-highlight {
		color: rgb(var(--t-content--action));
	}
}

.heading-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.heading-icon {
	flex-basis: 4rem;
	margin-right: 0;
	margin-left: auto;
	margin-bottom: 2rem;

	@include breakpoint(md) {
		flex-basis: 10rem;
	}

	svg {
		mix-blend-mode: difference;

		.theme-illustration-content-fill {
			fill: rgb(var(--t-content));
		}
	}
}