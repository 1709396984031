/* ======================================== */
/* checklist */
/* ======================================== */

@use 'abstracts' as *;

.check-list {
    display: block;
    margin: 2rem 0;

    @include breakpoint(md) {
        columns: 2;
    }

    &__item {
        position: relative;
        list-style: none;
        padding-left: 2em;

        -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
        page-break-inside: avoid; /* Firefox */
        break-inside: avoid; /* IE 10+ */

        &:before {
            content: "";       // This will get same font colour (ios safari fix)
            display: block;
            height: 2px;
            width: 0.75rem;
            background-color: rgb(var(--t-content));
            position: absolute;
            top: 0.6em;
            left: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}