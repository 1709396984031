/* ======================================== */
/* block article */
/* ======================================== */

@use "abstracts" as *;
@use 'sass:color';
@use 'sass:math';

$article-slider-dot-size: 1rem;

.articles {
    @extend %content-vertical-spacing;
    @extend %content-horizontal-spacing;
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
    display: grid; // Fix for slick slider
    overflow: visible;

    &__header {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.25rem;

        &--justify-content {
            justify-content: space-between;
        }
    }

    &__heading {
        text-align: center;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    //----------------------------------------
    // slider
    
    &-slider {
        min-width: 0; // Fix for slick slider
        min-height: 0; // Fix for slick slider
        margin-top: 2rem;
        margin-left: -1rem;
        margin-bottom: 2rem;
        width: calc(100% + 2rem);

        &__item {
            margin: 0 1rem;
        }

        &__controls {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
    
            &-dots { // override default slick style of circle dots
                position: relative;
                bottom: auto;
                z-index: 80;
                width: 100%;
                height: auto;
                width: auto;
    
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
    
                li {
                    margin: 0 0 0 math.div($article-slider-dot-size, 4);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    @include breakpoint(xs) {
                        margin: 0 0 0 math.div($article-slider-dot-size, 2);
                    }
    
                    &:first-child {
                        margin-left: 0;
                    }
    
                    &.slick-active {
    
                        button {
                            background-color: rgba(var(--t-content), 1);
                        }
                    }
                }
    
                button {
                    width: math.div($article-slider-dot-size, 2);
                    height: math.div($article-slider-dot-size, 2);
                    border-radius: $article-slider-dot-size;
                    background-color: transparent;
                    border: 1px solid rgba(var(--t-content), 1);
                    font-size: 1px;
                    text-indent: -99999px;
                    cursor: pointer;

                    @include breakpoint(xs) {
                        width: $article-slider-dot-size;
                        height: $article-slider-dot-size;
                    }
                }
            }
    
            $articles-slider-prevnext-size: 2rem;
    
            &-prev {
                transform: rotate(180deg);
            }
    
            &-prev,
            &-next {
                // display: none;
                z-index: 3;
                top: 50%;
                transform-origin: center center;
                width: $articles-slider-prevnext-size;
                background: transparent; // override base button style
                border: none;  // override base button style
                line-height: 0;  // override base button style
                padding: 0;  // override base button style
                transition: all 0.3s 0s ease-in-out;
                cursor: pointer;

                &.slick-disabled {
                    opacity: 0;
                }
    
                > svg {
                    width: 100%;
    
                    .theme-icon-bg {
                        fill: rgba(var(--t-linkbutton-icon-bg));
                        transition: fill 0.3s 0s ease-out;
                    }
                    .theme-icon-content-fill {
                        fill: rgb(var(--t-linkbutton-icon-content));
                        transition: fill 0.3s 0s ease-out;
                    }
                    .theme-icon-content-stroke {
                        stroke: rgb(var(--t-linkbutton-icon-content));
                        transition: stroke 0.3s 0s ease-out;
                    }
                }
    
                &:hover,
                &:focus {
    
                    > svg {
        
                        .theme-icon-bg {
                            fill: rgb(var(--t-linkbutton-icon-bg--action));
                        }
                        .theme-icon-content-fill {
                            fill: rgb(var(--t-linkbutton-icon-content--action));
                        }
                        .theme-icon-content-stroke {
                            stroke: rgb(var(--t-linkbutton-icon-content--action));
                        }
                    }
    
                }
            }
        }

        // Slick overrides - Site specific: You may or may not need or want these
        .slick-track {
            margin-left: 0; // left align cards when there aren't enough to fill out width
        }

        .slick-list {
            overflow: visible !important;
        }

        .slick-slide {
            opacity: 0.25;
            transition: opacity 0.5s 0s ease-in-out;

            &.slick-active {
                opacity: 1;
            }
        }
    }

    //----------------------------------------
    // grid

    &-grid {

        &.is-switching {
            opacity: 0;
            transition: all 0.3s 0s ease-in-out;
        }
    }

    &-no-result {
        border: 1px dashed;
        background: rgb(var(--t-bg));
        color: rgb(var(--t-content));
        padding: 4rem 2rem;
        text-align: center;
        grid-column: 1/4;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    //----------------------------------------
    // filter area - used for js loading

    &__filter-area {
        @extend %content-horizontal-spacing;
        @extend %content-vertical-spacing;
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;
        opacity: 1;
        transition: opacity 1s 0s ease-in-out;

        &.is-loading {
            opacity: 0.25;
            transition: opacity 0.3s 0s ease-in-out;
        }
    }

    //----------------------------------------
    // filter results - used for js loading

    &__filter-results {
        opacity: 1;
        transition: opacity 0.3s 0s ease-in-out;

        &.is-switching {
            opacity: 0;
        }
    }
}
