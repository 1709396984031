/* ======================================== */
/* block image */
/* ======================================== */

@use 'abstracts' as *;

.block-image {
    display: grid;
    overflow: hidden; // animation breaks block width
    background: rgba(var(--t-bg), 1);

    &__picture {
        max-width: 100%;
    }

    &__image {
        width: 100%;
        height: auto;
        display: block;
    }
}

.overlay {
    display: block;
    pointer-events: none;

    // Overlay Position
    &--top {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 30%;
        z-index: 2;

        --overlay-direction:180deg;
    }

    &--bottom {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30%;
        z-index: 3;

        --overlay-direction:0deg;
    }

    // Overlay Colour
    &--white { --t-gradient-blend-color: #{$white_rgb}; }
    &--black { --t-gradient-blend-color: #{$black_rgb}; }
    &--dark { --t-gradient-blend-color: #{$dark_rgb}; }
    &--light { --t-gradient-blend-color: #{$light_rgb}; }

    background: linear-gradient(var(--overlay-direction), rgba(var(--t-gradient-blend-color), 1), rgba(var(--t-gradient-blend-color), 0));
}