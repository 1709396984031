// ----------------------------------------
// form variables
// ----------------------------------------

// Sizing
$input-padding: 1em;
$form-font-size: 1rem;

// textbox, textarea and select
$input-border-radius: 0.125rem;
$input-border-width: 1px;

// radio and checkbox
$radio-checkbox-size: $form-font-size*1.5;
$radio-checkbox-indent: $radio-checkbox-size + 20;