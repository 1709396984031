/* ======================================== */
/* header */
/* ======================================== */

@use '../abstracts' as *;

$hamburger-size: 20px; // make it the same size as the navbar
$hamburger-line-width: 60%; // Sizing calculated from navbar height
$hamburger-line-height: 2px; // Sizing calculated from navbar height

.header {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transform: translateY(0px);         // Starting state, move/hide when popout is active.
    transition: all 0.5s ease-in-out;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @extend %content-horizontal-spacing;

    &.is-hidden {
        top:-135px;
    }

    &__toolbar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-basis: 100%;
        gap: 0.25rem;
    }

    &__utility {

        > ul {
            display: flex;
            gap: 0.5rem;

            > li {
                margin: 0;

                > a {
                    color: #fff;
                }
            }
        }
    }

    // Adjust COLOURS for mix-blend-mode: difference
    &--blend-mode {
        mix-blend-mode: difference;
        
        .hamburger {
            background-color: #fff;

            &:before,
            &:after {
                background-color: #fff;        
            }
        }
    }
}

// If popout/modal is active
.popout-is-active .header {
    transform: translateY(-100%);
}

.navigation-trigger {
    font-family: $font-stack;
    padding: 1em 0;
    border-radius: 0.25em;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-left: 1rem;
    border: none;
    background: transparent;
    color: rgb(var(--t-link--neutral));
    transition: all 0.3s 0s;
    pointer-events: auto;

    &__text {
        font-family: $font-stack;
        text-transform: uppercase;
        // letter-spacing: 0.125em;
        font-size: 0.75rem;
    }

    &__icon-wrapper {
        // code
    }

    // &.popout-is-active {
    &.naviagation-is-open {
        z-index: 40;

        &:hover,
        &:focus {
            .hamburger {
                background-color: transparent;
            }
        }
    }

    &:hover,
    &:focus {
        // color: rgb(var(--t-link));

        .hamburger {
            // background-color: rgb(var(--t-link));
    
            &:before,
            &:after {
                // background: rgb(var(--t-link));
            }
        }
    }
}

.hamburger {
    position: relative;
    display: block;
    transition-property: background, top;
    transition-duration: 0.2s;
    transform: translateZ(0);
    width: $hamburger-size;
    height: $hamburger-line-height;
    background-color: #fff;
    border-radius: $hamburger-line-height;
    pointer-events: initial;

    &:focus {
        outline: 0;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        transform: translateZ(0);
        border-radius: $hamburger-line-height;
        left: 0;
        background-color: #fff;
        transform-origin: center center;
        transition-property: background, transform, top, bottom, opacity;
        transition-duration: 0.2s;
    }

    &:before { 
        bottom: 300%; 
    }

    &:after { 
        top: 300%;
        width: 60%;
    }

    //--------------------------------------
    /* When the navigation is opened */
    // .navigation-trigger.popout-is-active & {
    .navigation-trigger.naviagation-is-open & {
        background-color: transparent;

        &:before {
            bottom: 0;
            transform: rotate(-45deg);
        }

        &:after {
            top: 0;
            transform: rotate(45deg);
            width: 100%;
        }
    }
}

.logo {
    pointer-events: initial;

    .theme-icon-content-fill {
        fill: #fff;
    }

    &--black {
        .theme-icon-content-fill {
            fill: #000;
        }
    }

    > svg,
    > img {
        height: 50px;
        display: block;
    }
}
