/* ======================================== */
/* webfonts */
/* ======================================== */


/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Avenir 45 Book by Linotype
 * URL: https://www.myfonts.com/products/pro-45-book-115678-avenir-368757
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Avenir 85 Heavy by Linotype
 * URL: https://www.myfonts.com/products/pro-85-heavy-115678-avenir-368789
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Avenir 65 Medium by Linotype
 * URL: https://www.myfonts.com/products/pro-65-medium-115678-avenir-368766
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Baskerville Classico Bold by Linotype
 * URL: https://www.myfonts.com/products/bold-baskerville-classico-368373
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Baskerville Classico Roman by Linotype
 * URL: https://www.myfonts.com/products/baskerville-classico-baskerville-classico-368367
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Northwell Alt by Set Sail Studios
 * URL: https://www.myfonts.com/products/alt-northwell-425379
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */


/* Avenir */
@font-face {
	font-family: "Avenir";
	src: 
        url('../../webfonts/Avenir45Book/font.woff2') format('woff2'),
        url('../../webfonts/Avenir45Book/font.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Avenir";
	src: 
        url('../../webfonts/Avenir65Medium/font.woff2') format('woff2'),
        url('../../webfonts/Avenir65Medium/font.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Avenir";
	src: 
        url('../../webfonts/Avenir85Heavy/font.woff2') format('woff2'),
        url('../../webfonts/Avenir85Heavy/font.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}


/* Baskerville Classico */
@font-face {
	font-family: "BaskervilleClassico";
	src: 
        url('../../webfonts/BaskervilleClassicoRoman/font.woff2') format('woff2'),
        url('../../webfonts/BaskervilleClassicoRoman/font.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "BaskervilleClassico";
	src: 
        url('../../webfonts/BaskervilleClassicoBold/font.woff2') format('woff2'),
        url('../../webfonts/BaskervilleClassicoBold/font.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

/* Northwell Alt */
@font-face {
	font-family: "NorthwellAlt";
	src: 
        url('../../webfonts/NorthwellAlt/font.woff2') format('woff2'),
        url('../../webfonts/NorthwellAlt/font.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

