/* ======================================== */
/* hero-prominent */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

$hero-prominent-dot-size: 1rem;

.hero-prominent {
    width: 100%;
    display: block;
    overflow: clip;

    // Portrait Viewport
    @media (orientation: portrait) {
        padding-top: math.percentage(math.div(3, 2));

        @include breakpoint(sm) {
            padding-top: math.percentage(math.div(2, 3));
        }
        
        &__inner {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
    // Landscape Viewport
    @media (orientation: landscape) {
        padding-top: initial;
        height: 100vh;
        // height: calc(100vh - #{$layout-gutter--xs});

        // @include breakpoint(sm) {
        //     height: calc(100vh - #{$layout-gutter--sm});
        // }
        // @include breakpoint(md) {
        //     height: calc(100vh - #{$layout-gutter--md});
        // }
        // @include breakpoint(lg) {
        //     height: calc(100vh - #{$layout-gutter--lg});
        // }
        // @include breakpoint(mx) {
        //     height: calc(100vh - #{$layout-gutter--mx});
        // }

        &__inner {
            position: relative;
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__controls {
        position: absolute;
        bottom: $hero-prominent-dot-size;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 0.5rem;
        left: 0;

        &-dots { // override default slick style of circle dots
            position: relative;
            bottom: auto;
            z-index: 80;
            width: 100%;
            height: auto;
            width: auto;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            li {
                margin: 0 0 0 math.div($hero-prominent-dot-size, 4);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @include breakpoint(xs) {
                    margin: 0 0 0 math.div($hero-prominent-dot-size, 2);
                }

                &:first-child {
                    margin-left: 0;
                }

                &.slick-active {

                    button {
                        background-color: rgb(var(--t-content));
                    }
                }
            }

            button {
                width: math.div($hero-prominent-dot-size, 2);
                height: math.div($hero-prominent-dot-size, 2);
                border-radius: $hero-prominent-dot-size;
                background-color: transparent;
                border: 1px solid rgb(var(--t-content));
                font-size: 1px;
                text-indent: -99999px;
                padding: 0;

                @include breakpoint(xs) {
                    width: $hero-prominent-dot-size;
                    height: $hero-prominent-dot-size;
                }
            }
        }
    }
    
    &__carousel {
        width: 100%;
        height: 100%;
        display: grid; // Seems to fix issue with slick slide blowout

        &-item {
            position: relative;
            width: 100%;
            height: 100%;

            &-inner {
                // slick slider attatches its own classes to the item so this ensures we have our own space to play with layout
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    display: none;
                }

                // Modifiers - left and right
                &--left,
                &--right {
                    align-items: flex-end;
                    @extend %content-vertical-spacing;
                    @extend %content-horizontal-spacing;

                    @include breakpoint(sm) {align-items: center;}

                    .hero-prominent__carousel-item-content {
                        width: 100%;
                        @include breakpoint(sm) {width: 50%;}
                        @include breakpoint(md) {width: 62.5%;}
                        @include breakpoint(lg) {width: 50%;}
                        @include breakpoint(mx) {width: 37.5%;}
                    }
                }

                // Modifiers - left
                &--left {
                    justify-content: flex-start;
                }
                
                // Modifiers - right
                &--right {
                    justify-content: flex-end;
                }
                
                // Modifiers - bottom
                &--default,
                &--bottom {
                    align-items: flex-end;
                    justify-content: center;

                    .hero-prominent__carousel-item-content {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        @extend %content-vertical-spacing;
                        @extend %content-horizontal-spacing;
                    }
                }
                
                // darkening layer
                &.has-darkening-layer {

                    &:before {
                        display: block;
                        // background: rgb(var(--r-slide-overlay), 0.9);
                        background: var(--t-slide-overlay);
                    }
                }

                &.has-btn-scroll {
                    @include breakpoint(sm-max) {
                        padding-bottom: 3rem;
                    }
                }
            }

            &-media {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &-picture {
                width: 100%;
                height: 100%;
            }

            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transform-origin: center;
                transition: transform 3s 0s ease-in-out;
            }

            &-iframevideo {
                top: 0;
                left: 0;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                position: absolute;
                object-fit: cover;

                @media (max-aspect-ratio: 16/9) {
                    height: 100%;
                    // padding-left: 177%; // 16:9 ratio
                    padding-left: 300%; // make it larger just in case theres more text or other content that will increase the height.
                }

                @media (min-aspect-ratio: 16/9) {
                    // padding-top: 56.25%; // 16:9 ratio
                    padding-top: 70%; // cover the left right gaps form vimeo vids
                    width: 100%;
                }

                &__iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            &-uploadvideo {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &-content {
                position: relative;
                z-index: 3;
                max-width: $max-content-width;

                & *:first-child {margin-top: 0;}
                & *:last-child {margin-bottom: 0;}
            }

        }


        $hero-carousel-prevnext-size: 2rem;

        &-prev {
            transform: rotate(180deg);
        }

        &-prev,
        &-next {
            // display: none;
            z-index: 3;
            top: 50%;
            transform-origin: center center;
            width: $hero-carousel-prevnext-size;
            background: transparent; // override base button style
            border: none;  // override base button style
            line-height: 0;  // override base button style
            padding: 0;  // override base button style
            transition: all 0.3s 0s ease-in-out;

            @include breakpoint(xs-max) {
                display: none !important; // override JS
            }

            &.slick-disabled {
                opacity: 0;
            }

            > svg {
                width: 100%;

                .theme-icon-bg {
                    fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-fill {
                    fill: rgb(var(--t-linkbutton-icon-content));
                    transition: fill 0.3s 0s ease-out;
                }
                .theme-icon-content-stroke {
                    stroke: rgb(var(--t-linkbutton-icon-content));
                    transition: stroke 0.3s 0s ease-out;
                }
            }

            &:hover,
            &:focus {
                background: transparent;

                > svg {
    
                    .theme-icon-bg {
                        fill: rgb(var(--t-linkbutton-icon-bg--action));
                    }
                    .theme-icon-content-fill {
                        fill: rgb(var(--t-linkbutton-icon-content--action));
                    }
                    .theme-icon-content-stroke {
                        stroke: rgb(var(--t-linkbutton-icon-content--action));
                    }
                }

            }
        }

    }

    // Button Scroll Down
    &__btn-wrapper {
        position: absolute;
        right: clamp(2rem, 5vw, 5rem);
        bottom: clamp(2rem, 5vw, 5rem);
        z-index: 9;
    }

    &__scroll-down {
        display: block;
        position: relative;
        bottom: 0;
        margin-left: auto;
        margin-right: 0;
        border: none;
        background: transparent;
        cursor: pointer;

        &:hover, &:focus {
            animation: slide-down;
            animation-duration: 0.5s;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }

        .theme-icon-content-stroke {
            stroke: rgb(var(--t-content));
        }
    }

    // Slick Slider overrides

    .slick {
        &-list,
        &-track,
        &-slide {
            height: 100%;
        }
    }
}

// Slick Slider overrides
.jsHeroProminentCarousel {
    
    .hero-prominent__carousel-item-content {
        opacity: 0;
        transform: translateY(2vh);
        transition-property: transform, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 1s;
        transition-delay: 0s;
    }
}

@keyframes hero-image-pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

.is-animating {

    .hero-prominent__carousel-item-content {
        opacity: 1;
        transform: translateY(0);
    }

    .hero-prominent__carousel-item-img {
        animation: hero-image-pulse 11s ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }
}

// Scroll down animation 
@keyframes slide-down {
    0% {
        bottom: 0;
    }

    100% {
        bottom: -1rem;
    }
}