/* ======================================== */
/* tag-list */
/* ======================================== */

.tag-list {
    display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

    &__tag {
        text-transform: uppercase;
        padding: 0.25em 0.5em 0.15em 0.5em;
        font-size: 0.625rem;
        letter-spacing: 1px;
        background-color: rgba(var(--t-content), 0.05);
        color: var(--t-tag-content);
    }
}