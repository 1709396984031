/* ======================================== */
/* collage */
/* ======================================== */

@use 'abstracts' as *;
@use 'sass:math';

.block-collage {
    @extend %content-vertical-spacing;
}

.collage-items {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: clamp(3rem, 7.5vw, 8rem) 0;
}

.collage {
    width: 100%;
    display: grid;
    @extend %content-horizontal-spacing;
    row-gap: 2rem;

    @include breakpoint(md) {
        padding-left: 0;
        padding-right: 0;
        row-gap: 4rem;
    }

    // Has no media items
    &--0-media {

        @include breakpoint(md-max) {
            // | 1fr | 2fr | 1fr |
            grid-template-columns: [c-line-1] 1fr [c-line-2] 2fr [c-line-3] 1fr [c-line-4];
            grid-template-rows: [r-line-1] auto [r-line-2];
            column-gap: 2rem; // Okay to use gap here as we only ever have an icon if anything
        }

        @include breakpoint(sm-max) {
            grid-template-rows: [r-line-1] auto [r-line-2] auto [r-line-3];
        }

        @include breakpoint(md) {
            // | 2fr | 8fr | 2fr |
            grid-template-columns: [c-line-1] 2fr [c-line-2] 8fr [c-line-3] 2fr [c-line-4];
            align-items: end;
            column-gap: 4rem; // Okay to use gap here as we only ever have an icon if anything
        }

        .collage-content {
            grid-row: r-line-1 / r-line-2;

            @include breakpoint(md-max) {
                grid-column: c-line-1 / c-line-3;
            }
            @include breakpoint(sm-max) {
                grid-column: c-line-1 / c-line-4;
            }
            @include breakpoint(md) {
                grid-column: c-line-2 / c-line-3;
            }
        }

        &.collage--has-icon {
            align-items: end;

            @include breakpoint(md) {
                // | 2fr | 6fr | 2fr | 2fr |
                grid-template-columns: [c-line-1] 2fr [c-line-2] 6fr [c-line-3] 2fr [c-line-4] 2fr [c-line-5];
            }

            &.collage--flipped {

                @include breakpoint(md-max) {
                    // | 1fr | 2fr | 1fr |
                    grid-template-columns: [c-line-4] 1fr [c-line-3] 2fr [c-line-2] 1fr [c-line-1];
                }

                @include breakpoint(md) {
                    // | 2fr | 2fr | 6fr | 2fr |
                    grid-template-columns: [c-line-5] 2fr [c-line-4] 2fr [c-line-3] 6fr [c-line-2] 2fr [c-line-1];
                }
            }

            .collage-content {
                @include breakpoint(md-max) {
                    grid-column: c-line-1 / c-line-3;
                }
                @include breakpoint(sm-max) {
                    grid-column: c-line-1 / c-line-4;
                }
                @include breakpoint(md) {
                    grid-column: c-line-2 / c-line-3;
                }
            }

            .collage-icon {
                grid-row: r-line-1 / r-line-2;

                @include breakpoint(md-max) {
                    grid-column: c-line-3 / c-line-4;
                    align-items: end;
                }
                
                @include breakpoint(sm-max) {
                    grid-row: r-line-2 / r-line-3;
                }

                @include breakpoint(md) {
                    grid-column: c-line-3 / c-line-4;
                }
            }
        }

    }

    // Has 1 media item
    &--1-media {
        
        @include breakpoint(md-max) {
            // | 1fr | 1fr | 1fr | 1fr |
            grid-template-columns: [c-line-1] 1fr [c-line-2] 1fr [c-line-3] 1fr [c-line-4] 1fr [c-line-5];

            // | auto | auto | auto | 
            grid-template-rows: [r-line-1] auto [r-line-2] auto [r-line-3] auto [r-line-4];
        }

        @include breakpoint(md) {
            // | 1/12 | 4/12 | 2/12 | 4/12 | 1/12 |
            grid-template-columns: [c-line-1] 1fr [c-line-2] 4fr [c-line-3] 2fr [c-line-4] 4fr [c-line-5] 1fr [c-line-6];

            // | 1fr | auto |
            grid-template-rows: [r-line-1] 1fr [r-line-2] auto [r-line-3];
        }

        &.collage--flipped {

            @include breakpoint(md-max) {
                // | 1fr | 1fr | 1fr | 1fr |
                grid-template-columns: [c-line-5] 1fr [c-line-4] 1fr [c-line-3] 1fr [c-line-2] 1fr [c-line-1];
            }

            @include breakpoint(md) {
                // | 1/12 | 4/12 | 2/12 | 4/12 | 1/12 |
                grid-template-columns: [c-line-6] 1fr [c-line-5] 4fr [c-line-4] 2fr [c-line-3] 4fr [c-line-2] 1fr [c-line-1];
            }

            .collage-content {
                @include breakpoint(md) {
                    padding-left: 4rem;
                    padding-right: 0;
                }
            }

            .collage-icon {
                padding-right: 0; // Reset from default

                @include breakpoint(md-max) {
                    padding-left: 2rem;
                }

                @include breakpoint(md) {
                    padding-left: 4rem;
                }
            }
        }

        // Content
        .collage-content {

            @include breakpoint(md-max) {
                grid-row: r-line-1 / r-line-2;
                grid-column: c-line-1 / c-line-4;
            }

            @include breakpoint(sm-max) {
                grid-column: c-line-1 / c-line-5;
            }

            @include breakpoint(md) {
                grid-row: r-line-1 / r-line-2;
                grid-column: c-line-2 / c-line-4;
                align-self: end;
                padding-right: 4rem;
            }
        }

        // Media item 1
        .collage-media--1 {

            @include breakpoint(md-max) {
                grid-row: r-line-2 / r-line-3;
                grid-column: c-line-3 / c-line-5;
            }

            @include breakpoint(md) {
                grid-row: r-line-1 / r-line-3;
            }

            &.collage-media {

                @include breakpoint(md) {
                    align-self: end;

                    &--square {
                        grid-column: c-line-4 / c-line-6;
                    }
                    &--landscape,
                    &--default {
                        grid-column: c-line-4 / c-line-6;
                    }
                    &--portrait {
                        grid-column: c-line-4 / c-line-5;
                    }
                }
            }
        }

        .collage-icon {
            @include breakpoint(md-max) {
                grid-row: r-line-2 / r-line-3;
                grid-column: c-line-2 / c-line-3;
                padding-right: 2rem;
            }

            @include breakpoint(md) {
                grid-row: r-line-2 / r-line-3;
                grid-column: c-line-3 / c-line-4;
                justify-self: end;
                padding-right: 4rem;
            }
                
        }

    }

    // Has 2 media items
    &--2-media {

        @include breakpoint(md-max) {
            // | 1fr | 1fr | 2rem | 1fr | 1fr |
            grid-template-columns: [c-line-1] 1fr [c-line-2] 1fr [c-line-3] 2rem [c-line-4] 1fr [c-line-5] 1fr [c-line-6];

            // | auto | auto | auto | 
            grid-template-rows: [r-line-1] auto [r-line-2] auto [r-line-3] auto [r-line-4];
        }

        @include breakpoint(md) {
            // | 1/12 | 3/12 | 3/12 | 1/12 | 2/12 | 1/12 | 1/12 |
            grid-template-columns: [c-line-1] 1fr [c-line-2] 3fr [c-line-3] 3fr [c-line-4] 1fr [c-line-5] 2fr [c-line-6] 1fr [c-line-7] 1fr [c-line-8];

            // | auto | auto | auto | 
            grid-template-rows: [r-line-1] auto [r-line-2] 8rem [r-line-3] auto [r-line-4];
        }

        &.collage--flipped {
            @include breakpoint(md-max) {
                // | 1fr | 1fr | 2rem | 1fr | 1fr |
                grid-template-columns: [c-line-6] 1fr [c-line-5] 1fr [c-line-4] 2rem [c-line-3] 1fr [c-line-2] 1fr [c-line-1];
            }

            @include breakpoint(md) {
                // | 1/12 | 1/12 | 2/12 | 1/12 | 3/12 | 3/12 | 1/12 |
                grid-template-columns: [c-line-8] 1fr [c-line-7] 1fr [c-line-6] 2fr [c-line-5] 1fr [c-line-4] 3fr [c-line-3] 3fr [c-line-2] 1fr [c-line-1];
            }

            .collage-content {
                @include breakpoint(md) {
                    padding-left: 4rem;
                }
            }

            .collage-icon {
                padding-left: 0;
                padding-right: 2rem;

                @include breakpoint(md) {
                    padding-right: 4rem;
                    padding-left: 0; // remove existing padding for default option
                }
            }
        }

        // Content
        .collage-content {

            @include breakpoint(md-max) {
                grid-row: r-line-1 / r-line-2;
                grid-column: c-line-1 / c-line-5;
            }

            @include breakpoint(sm-max) {
                grid-row: r-line-1 / r-line-2;
                grid-column: c-line-1 / c-line-6;
            }

            @include breakpoint(md) {
                grid-row: r-line-1 / r-line-2;
                grid-column: c-line-2 / c-line-4;
                align-self: end;
                // padding-bottom: 4rem;
                padding-right: 4rem;
            }
        }

        .collage-media {
            mix-blend-mode: var(--t-pic-blend-mode);
        }

        // Media item 1
        .collage-media--1 {

            @include breakpoint(md-max) {
                grid-row: r-line-3 / r-line-4;
                grid-column: c-line-3 / c-line-6;
            }

            @include breakpoint(md) {
                    grid-row: r-line-1 / r-line-3;
            }

            &.collage-media {

                @include breakpoint(md) {
                    align-self: end;
                    &--square {
                        grid-column: c-line-4 / c-line-8;
                    }
                    &--landscape,
                    &--default {
                        grid-column: c-line-4 / c-line-8;
                    }
                    &--portrait {
                        grid-column: c-line-4 / c-line-7;
                    }
                }
            }
        }

        // Media item 2
        .collage-media--2 {

            @include breakpoint(md-max) {
                grid-row: r-line-3 / r-line-4;
                grid-column: c-line-1 / c-line-4;
                margin-top: 4rem; // bump it down as no icon
            }

            @include breakpoint(md) {
                grid-row: r-line-2 / r-line-4;
                z-index: 2;
            }

            &.collage-media {
                @include breakpoint(md) {
                    &--square {
                        grid-column: c-line-3 / c-line-5;
                    }
                    &--landscape,
                    &--default {
                        grid-column: c-line-1 / c-line-5;
                    }
                    &--portrait {
                        grid-column: c-line-3 / c-line-5;
                    }
                }
            }
        }

        &.collage--has-icon {
            .collage-media--2 {
                @include breakpoint(md-max) {
                    margin-top: -4rem; // bump it up as we have icon next to it
                }
                @include breakpoint(sm-max) {
                    margin-top: -2rem; // bump it up as we have icon next to it
                }
            }
        }

        .collage-icon {
            padding-left: 2rem;

            @include breakpoint(md-max) {
                grid-row: r-line-2 / r-line-3;
                grid-column: c-line-4 / c-line-5;
                align-self: end;
            }

            @include breakpoint(xs-max) {
                grid-column: c-line-4 / c-line-6;
            }

            @include breakpoint(md) {
                grid-row: r-line-3 / r-line-4;
                grid-column: c-line-5 / c-line-6;
                padding-left: 4rem;
            }
        }
    }

    &-content {

        > *:last-child {
            margin-bottom: 0;

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-icon {
        width: 100%;
        mix-blend-mode: difference; // Icon must be white colored for this effect to work

        > svg {
            width: 100%;
        }
    }
    
    &__picture {
        overflow: hidden;
        display: block;
    }

    &__img {
        width: 100%;
        height: 100%;
        display: block;
        transition: transform 3s 0s ease-in-out;
    }

    &__iframevideo {
        position: relative;
        width: 100%;
        padding-top: 56.25%; // 16:9 ratio is default sizing of iframe
        overflow: hidden;

        &__iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &--default {
            &__iframe {
            }
        }

        &--square,
        &--landscape,
        &--portrait {

            .collage__iframevideo__iframe {
            }
        }

        &--square {
            padding-top: 100%; // 1:1 ratio

            .collage__iframevideo__iframe {
                width: 177%;
                transform: translateX(-23%);
            }
        }
        &--landscape {
            padding-top: 66.666666%; // 3:2 ratio

            .collage__iframevideo__iframe {
            }
        }
        &--portrait {
            padding-top: 150%; // 2:3 ratio

            .collage__iframevideo__iframe {
                width: 265%;
                transform: translateX(-35%);
                
            }
        }
    }

    &__uploadvideo {
        position: relative;
        width: 100%;
        padding-top: 56.25%; // 16:9 ratio is default sizing of iframe

        &--square {
            padding-top: 100%; // 1:1 ratio
        }
        &--landscape {
            padding-top: 66.666666%; // 3:2 ratio
        }
        &--portrait {
            padding-top: 150%; // 2:3 ratio
        }

        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
