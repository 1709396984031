@use '../abstracts' as *;

$padding-size: 2rem;

.site-notice {
    @extend %content-horizontal-spacing;

    background: rgb(var(--t-bg));
    color: rgb(var(--t-content));
    display: none;
    gap: 2rem;
    padding-top: $padding-size;
    padding-bottom: $padding-size;
    z-index: 99;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
        padding-right: $padding-size*2;

        @include breakpoint(md) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__text {
        margin-bottom: 0;
    }

    &__link {
        text-decoration: none;
        padding: 0.5rem 1rem;
        background: rgb(var(--t-btn-bg));
        border: solid 1px;
        border-color: rgb(var(--t-btn-border));
        color: rgb(var(--t-btn-content));
        white-space: nowrap;

        &:hover,
        &:focus {
            background: rgb(var(--t-bg--action));
            border-color: rgb(var(--t-btn-border--action));
            color: rgb(var(--t-content--action));
        }
    }

    &__close {
        position: absolute;
        top: $padding-size;
        right: $padding-size;
        flex-shrink: 0;
        width: 2rem;
        background: transparent;
        padding: 0;
        margin: 0;
        margin-left: auto;
        border: none;

        > svg {
            width: 100%;

            .theme-icon-bg {
                fill: rgba(var(--t-linkbutton-icon-bg), 0.05);
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content));
                transition: fill 0.3s 0s ease-in-out;
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content));
                transition: stroke 0.3s 0s ease-in-out;
            }
        }

        &:hover,
        &:focus {

            .theme-icon-bg {
                fill: rgb(var(--t-linkbutton-icon-bg--action));
            }
            .theme-icon-content-fill {
                fill: rgb(var(--t-linkbutton-icon-content--action));
            }
            .theme-icon-content-stroke {
                stroke: rgb(var(--t-linkbutton-icon-content--action));
                
            }
        }
    }

    // Position Modifiers
    &--default {
        // Code
    }

    &--top {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
    }

    &--bottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &.is-hidden {
        display: none;
    }

    &.is-visible {
        display: block;
    }
}
