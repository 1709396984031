/* ======================================== */
/* rich-text */
/* ======================================== */

@use 'abstracts' as *;

.rich-text {

	h2,h3,h4,h5,h6 {
		text-transform: none;
	}

	h1 {
		margin-bottom: 0.5em;
	}

	p + h2,
	p + h3,
	p + h4,
	p + blockquote {
		margin-top: 1em;
	}

	ul,ol {
		margin: 2em 0;
		padding: 0 0 0 2em;
		text-align: left;

		p {font-size: 1rem;}

		li {
			margin-bottom: 0.5em;
			line-height: 1.5;
			font-weight: 400;
		}
	}

	ul {
		list-style-type: disc;
	}

	ul.list-simple,
	ol.list-simple {
		list-style-type: none;
		padding: 0;
	}

	img {
		max-width: 100%;
	}

	iframe {
		margin-bottom: 2em;
	}

	figcaption {
		margin: 1em 0 2em 0;
		text-align: center;
	}

	a:not(.link-btn) {
		color: currentColor;
		background-color: rgba(var(--t-link-bg), 0);
		text-decoration: underline;
		text-decoration-color: rgba(var(--t-link-bg--action), 1);
		transition: background-color 0.2s;
		font-family: $font-stack;

		&:hover {
			background-color: rgba(var(--t-link-bg--action), 1);
			color: rgba(var(--t-link--action), 1);
		}
	}

	.btn {
		text-decoration: none;
		margin-top: 2em;
	}

	p > .btn {
		margin-top: initial;
	}

	.align_center{display:block; margin:0 auto;}

	.text-tiny {
		font-size: 0.75em;
    }
    
    blockquote {
        p { 
            line-height: 1.25;
        }
    }
}

.TextformatterVideoEmbed {
	padding-top: 0 !important;
}

/* Controlled through CK Editor */
img {
	&.right,
	&.align_right,
	&.align-right {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem !important;
		
		@include breakpoint(sm) {
			float: right;
			margin-left: 2rem;
		}
	}

	&.left,
	&.align_left,
	&.align-left {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem !important;

		@include breakpoint(sm) {
			float: left;
			margin-right: 2rem;
		}
	}

	&.align_center,
	&.align-center {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem !important;
	}
}